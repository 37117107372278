import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase-config';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Typography, Container, Box, CircularProgress, Alert } from '@mui/material';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const navigate = useNavigate();

    const handleSignUp = async () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setLoading(true);
        setError(null);
        setSuccessMessage(null); // Reset success message

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            setSuccessMessage('Registration successful! You will be directed to the Login Page.');
            console.log('User successfully registered'); // Debugging log
            
            // Delay navigation to allow the user to see the success message
            setTimeout(() => {
                navigate('/signin');
            }, 2000); // Navigate after 5 seconds
        } catch (error) {
            console.error('Error signing up:', error);
            setError(error.code); // Display the error code
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Box sx={{
                textAlign: 'center',
                padding: { xs: 3, sm: 4 }, // Adjust padding for mobile and larger screens
                borderRadius: 5,
                boxShadow: 10,
                width: '100%',
                maxWidth: '400px' // Set a max-width for larger screens
            }}>
                <Typography variant="h5" gutterBottom>
                    Sign Up
                </Typography>
                
                {/* Display Success Message */}
                {successMessage && <Alert severity="success">{successMessage}</Alert>}
                
                {/* Display Error Message */}
                {error && <Alert severity="error">{error}</Alert>}
                
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignUp}
                    sx={{ marginTop: 2, fontSize: '0.8rem', padding: '10px 20px' }}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Sign Up'}
                </Button>
            </Box>
        </Container>
    );
};

export default SignUp;
