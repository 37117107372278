export const SYMBOL_MAPPINGS = {
    1793: 'AARTIIND',
    3329: 'ABB',
    4583169: 'ABBOTINDIA',
    5533185: 'ABCAPITAL',
    7707649: 'ABFRL',
    5633: 'ACC',
    6401: 'ADANIENT',
    3861249: 'ADANIPORTS',
    2995969: 'ALKEM',
    325121: 'AMBUJACEM',
    40193: 'APOLLOHOSP',
    41729: 'APOLLOTYRE',
    3365633: 'PVRINOX',
    54273: 'ASHOKLEY',
    60417: 'ASIANPAINT',
    3691009: 'ASTRAL',
    67329: 'ATUL',
    5436929: 'AUBANK',
    70401: 'AUROPHARMA',
    1510401: 'AXISBANK',
    4267265: 'BAJAJ-AUTO',
    4268801: 'BAJAJFINSV',
    81153: 'BAJFINANCE',
    85761: 'BALKRISIND',
    87297: 'BALRAMCHIN',
    579329: 'BANDHANBNK',
    1195009: 'BANKBARODA',
    94977: 'BATAINDIA',
    98049: 'BEL',
    103425: 'BERGEPAINT',
    108033: 'BHARATFORG',
    2714625: 'BHARTIARTL',
    112129: 'BHEL',
    2911489: 'BIOCON',
    558337: 'BOSCHLTD',
    134657: 'BPCL',
    140033: 'BRITANNIA',
    1790465: 'BSOFT',
    2763265: 'CANBK',
    149249: 'CANFINHOME',
    163073: 'CHAMBLFERT',
    175361: 'CHOLAFIN',
    177665: 'CIPLA',
    5215745: 'COALINDIA',
    2955009: 'COFORGE',
    3876097: 'COLPAL',
    1215745: 'CONCOR',
    189185: 'COROMANDEL',
    4376065: 'CROMPTON',
    1459457: 'CUB',
    486657: 'CUMMINSIND',
    197633: 'DABUR',
    2067201: 'DALBHARAT',
    5105409: 'DEEPAKNTR',
    2800641: 'DIVISLAB',
    5552641: 'DIXON',
    3771393: 'DLF',
    225537: 'DRREDDY',
    232961: 'EICHERMOT',
    245249: 'ESCORTS',
    173057: 'EXIDEIND',
    261889: 'FEDERALBNK',
    1207553: 'GAIL',
    1895937: 'GLENMARK',
    3463169: 'GMRINFRA',
    300545: 'GNFC',
    2585345: 'GODREJCP',
    4576001: 'GODREJPROP',
    3039233: 'GRANULES',
    315393: 'GRASIM',
    2713345: 'GUJGASLTD',
    589569: 'HAL',
    2513665: 'HAVELLS',
    1086465: 'HDFCAMC',
    341249: 'HDFCBANK',
    119553: 'HDFCLIFE',
    345089: 'HEROMOTOCO',
    348929: 'HINDALCO',
    4592385: 'HINDCOPPER',
    359937: 'HINDPETRO',
    356865: 'HINDUNILVR',
    1270529: 'ICICIBANK',
    5573121: 'ICICIGI',
    548353: 'MFSL',
    4774913: 'ICICIPRULI',
    3677697: 'IDEA',
    3060993: 'IDFC',
    2863105: 'IDFCFIRSTB',
    56321: 'IEX',
    2883073: 'IGL',
    387073: 'INDHOTEL',
    387841: 'INDIACEM',
    2745857: 'INDIAMART',
    2865921: 'INDIGO',
    1346049: 'INDUSINDBK',
    7458561: 'INDUSTOWER',
    408065: 'INFY',
    523009: 'RAMCOCEM',
    415745: 'IOC',
    418049: 'IPCALAB',
    3484417: 'IRCTC',
    424961: 'ITC',
    1723649: 'JINDALSTEL',
    3397121: 'JKCEMENT',
    3001089: 'JSWSTEEL',
    4632577: 'JUBLFOOD',
    492033: 'KOTAKBANK',
    2983425: 'LALPATHLAB',
    4923905: 'LAURUSLABS',
    511233: 'LICHSGFIN',
    4488705: 'MGL',
    2939649: 'LT',
    6386689: 'LTF',
    4561409: 'LTIM',
    4752385: 'LTTS',
    2672641: 'LUPIN',
    519937: 'M&M',
    3400961: 'M&MFIN',
    4879617: 'MANAPPURAM',
    1041153: 'MARICO',
    2815745: 'MARUTI',
    7982337: 'MCX',
    2452737: 'METROPOLIS',
    1076225: 'MOTHERSON',
    1152769: 'MPHASIS',
    582913: 'MRF',
    6054401: 'MUTHOOTFIN',
    1629185: 'NATIONALUM',
    3520257: 'NAUKRI',
    3756033: 'NAVINFLUOR',
    4598529: 'NESTLEIND',
    3924993: 'NMDC',
    2977281: 'NTPC',
    5181953: 'OBEROIRLTY',
    2748929: 'OFSS',
    633601: 'ONGC',
    3689729: 'PAGEIND',
    617473: 'PEL',
    4701441: 'PERSISTENT',
    2905857: 'PETRONET',
    3660545: 'PFC',
    681985: 'PIDILITIND',
    6191105: 'PIIND',
    2730497: 'PNB',
    2455041: 'POLYCAB',
    3834113: 'POWERGRID',
    4708097: 'RBLBANK',
    3930881: 'RECLTD',
    738561: 'RELIANCE',
    758529: 'SAIL',
    4600577: 'SBICARD',
    5582849: 'SBILIFE',
    779521: 'SBIN',
    794369: 'SHREECEM',
    1102337: 'SHRIRAMFIN',
    806401: 'SIEMENS',
    837889: 'SRF',
    857857: 'SUNPHARMA',
    3431425: 'SUNTV',
    2622209: 'SYNGENE',
    871681: 'TATACHEM',
    952577: 'TATACOMM',
    878593: 'TATACONSUM',
    884737: 'TATAMOTORS',
    877057: 'TATAPOWER',
    895745: 'TATASTEEL',
    2953217: 'TCS',
    3465729: 'TECHM',
    897537: 'TITAN',
    900609: 'TORNTPHARM',
    502785: 'TRENT',
    2170625: 'TVSMOTOR',
    4278529: 'UBL',
    2952193: 'ULTRACEMCO',
    2531841: 'UNIONBANK',
    1214721: 'UPL',
    784129: 'VEDL',
    1850625: 'VOLTAS',
    969473: 'WIPRO',
    94849: 'ZEEL',
    25601: 'ZYDUSLIFE',
};


// symbolConstants.js
export const SYMBOL_COMPANYNAME = [
    { symbol: 'AARTIIND', name: 'Aarti Industries Limited' },
    { symbol: 'ABB', name: 'ABB India Limited' },
    { symbol: 'ABCAPITAL', name: 'Aditya Birla Capital Limited' },
    { symbol: 'ABFRL', name: 'Aditya Birla Fashion and Retail Limited' },
    { symbol: 'ACC', name: 'ACC Limited' },
    { symbol: 'ADANIENT', name: 'Adani Enterprises Limited' },
    { symbol: 'ADANIPORTS', name: 'Adani Ports and Special Economic Zone Limited' },
    { symbol: 'ALKEM', name: 'Alkem Laboratories Limited' },
    { symbol: 'AMBUJACEM', name: 'Ambuja Cements Limited' },
    { symbol: 'APOLLOHOSP', name: 'Apollo Hospitals Enterprise Limited' },
    { symbol: 'APOLLOTYRE', name: 'Apollo Tyres Limited' },
    { symbol: 'ASHOKLEY', name: 'Ashok Leyland Limited' },
    { symbol: 'ASIANPAINT', name: 'Asian Paints Limited' },
    { symbol: 'ASTRAL', name: 'Astral Limited' },
    { symbol: 'ATUL', name: 'Atul Limited' },
    { symbol: 'AUROPHARMA', name: 'Aurobindo Pharma Limited' },
    { symbol: 'AXISBANK', name: 'Axis Bank Limited' },
    { symbol: 'BAJAJ-AUTO', name: 'Bajaj Auto Limited' },
    { symbol: 'BAJAJFINSV', name: 'Bajaj Finserv Limited' },
    { symbol: 'BAJFINANCE', name: 'Bajaj Finance Limited' },
    { symbol: 'BALKRISIND', name: 'Balkrishna Industries Limited' },
    { symbol: 'BALRAMCHIN', name: 'Balrampur Chini Mills Limited' },
    { symbol: 'BANKBARODA', name: 'Bank of Baroda' },
    { symbol: 'BATAINDIA', name: 'Bata India Limited' },
    { symbol: 'BEL', name: 'Bharat Electronics Limited' },
    { symbol: 'BERGEPAINT', name: 'Berger Paints (I) Limited' },
    { symbol: 'BHARATFORG', name: 'Bharat Forge Limited' },
    { symbol: 'BHARTIARTL', name: 'Bharti Airtel Limited' },
    { symbol: 'BHEL', name: 'Bharat Heavy Electricals Limited' },
    { symbol: 'BIOCON', name: 'Biocon Limited' },
    { symbol: 'BOSCHLTD', name: 'Bosch Limited' },
    { symbol: 'BPCL', name: 'Bharat Petroleum Corporation Limited' },
    { symbol: 'BRITANNIA', name: 'Britannia Industries Limited' },
    { symbol: 'BSOFT', name: 'BIRLASOFT LIMITED' },
    { symbol: 'CANBK', name: 'Canara Bank' },
    { symbol: 'CANFINHOME', name: 'Can Fin Homes Limited' },
    { symbol: 'CHAMBLFERT', name: 'Chambal Fertilizers & Chemicals Limited' },
    { symbol: 'CHOLAFIN', name: 'Cholamandalam Investment and Finance Company Limited' },
    { symbol: 'CIPLA', name: 'Cipla Limited' },
    { symbol: 'COALINDIA', name: 'Coal India Limited' },
    { symbol: 'COFORGE', name: 'Coforge Limited' },
    { symbol: 'CONCOR', name: 'Container Corporation of India Limited' },
    { symbol: 'COROMANDEL', name: 'Coromandel International Limited' },
    { symbol: 'CROMPTON', name: 'Crompton Greaves Consumer Electricals Limited' },
    { symbol: 'CUMMINSIND', name: 'Cummins India Limited' },
    { symbol: 'DABUR', name: 'Dabur India Limited' },
    { symbol: 'DALBHARAT', name: 'Dalmia Bharat Limited' },
    { symbol: 'DEEPAKNTR', name: 'Deepak Nitrite Limited' },
    { symbol: 'DIVISLAB', name: 'Divi\'s Laboratories Limited' },
    { symbol: 'DIXON', name: 'Dixon Technologies (India) Limited' },
    { symbol: 'DLF', name: 'DLF Limited' },
    { symbol: 'DRREDDY', name: 'Dr. Reddy\'s Laboratories Limited' },
    { symbol: 'EICHERMOT', name: 'Eicher Motors Limited' },
    { symbol: 'ESCORTS', name: 'Escorts Kubota Limited' },
    { symbol: 'EXIDEIND', name: 'Exide Industries Limited' },
    { symbol: 'FEDERALBNK', name: 'The Federal Bank Limited' },
    { symbol: 'GAIL', name: 'GAIL (India) Limited' },
    { symbol: 'GLENMARK', name: 'Glenmark Pharmaceuticals Limited' },
    { symbol: 'GMRINFRA', name: 'GMR Airports Infrastructure Limited' },
    { symbol: 'GNFC', name: 'Gujarat Narmada Valley Fertilizers and Chemicals Limited' },
    { symbol: 'GODREJCP', name: 'Godrej Consumer Products Limited' },
    { symbol: 'GODREJPROP', name: 'Godrej Properties Limited' },
    { symbol: 'GRANULES', name: 'Granules India Limited' },
    { symbol: 'GRASIM', name: 'Grasim Industries Limited' },
    { symbol: 'GUJGASLTD', name: 'Gujarat Gas Limited' },
    { symbol: 'HAL', name: 'Hindustan Aeronautics Limited' },
    { symbol: 'HAVELLS', name: 'Havells India Limited' },
    { symbol: 'HCLTECH', name: 'HCL Technologies Limited' },
    { symbol: 'HDFCAMC', name: 'HDFC Asset Management Company Limited' },
    { symbol: 'HDFCBANK', name: 'HDFC Bank Limited' },
    { symbol: 'HEROMOTOCO', name: 'Hero MotoCorp Limited' },
    { symbol: 'HINDALCO', name: 'Hindalco Industries Limited' },
    { symbol: 'HINDCOPPER', name: 'Hindustan Copper Limited' },
    { symbol: 'HINDPETRO', name: 'Hindustan Petroleum Corporation Limited' },
    { symbol: 'HINDUNILVR', name: 'Hindustan Unilever Limited' },
    { symbol: 'ICICIBANK', name: 'ICICI Bank Limited' },
    { symbol: 'IDEA', name: 'Vodafone Idea Limited' },
    { symbol: 'IDFC', name: 'IDFC Limited' },
    { symbol: 'IDFCFIRSTB', name: 'IDFC First Bank Limited' },
    { symbol: 'IEX', name: 'Indian Energy Exchange Limited' },
    { symbol: 'IGL', name: 'Indraprastha Gas Limited' },
    { symbol: 'INDHOTEL', name: 'The Indian Hotels Company Limited' },
    { symbol: 'INDIACEM', name: 'The India Cements Limited' },
    { symbol: 'INDIAMART', name: 'Indiamart Intermesh Limited' },
    { symbol: 'INDIGO', name: 'InterGlobe Aviation Limited' },
    { symbol: 'INDUSINDBK', name: 'IndusInd Bank Limited' },
    { symbol: 'INDUSTOWER', name: 'Indus Towers Limited' },
    { symbol: 'INFY', name: 'Infosys Limited' },
    { symbol: 'IOC', name: 'Indian Oil Corporation Limited' },
    { symbol: 'IPCALAB', name: 'IPCA Laboratories Limited' },
    { symbol: 'IRCTC', name: 'Indian Railway Catering And Tourism Corporation Limited' },
    { symbol: 'ITC', name: 'ITC Limited' },
    { symbol: 'JINDALSTEL', name: 'Jindal Steel & Power Limited' },
    { symbol: 'JKCEMENT', name: 'JK Cement Limited' },
    { symbol: 'JSWSTEEL', name: 'JSW Steel Limited' },
    { symbol: 'JUBLFOOD', name: 'Jubilant Foodworks Limited' },
    { symbol: 'KOTAKBANK', name: 'Kotak Mahindra Bank Limited' },
    { symbol: 'LALPATHLAB', name: 'Dr. Lal Path Labs Ltd.' },
    { symbol: 'LAURUSLABS', name: 'Laurus Labs Limited' },
    { symbol: 'LICHSGFIN', name: 'LIC Housing Finance Limited' },
    { symbol: 'LT', name: 'Larsen & Toubro Limited' },
    { symbol: 'LTF', name: 'L&T Finance Limited' },
    { symbol: 'LTIM', name: 'LTIMindtree Limited' },
    { symbol: 'LTTS', name: 'L&T Technology Services Limited' },
    { symbol: 'LUPIN', name: 'Lupin Limited' },
    { symbol: 'MANAPPURAM', name: 'Manappuram Finance Limited' },
    { symbol: 'MARICO', name: 'Marico Limited' },
    { symbol: 'MARUTI', name: 'Maruti Suzuki India Limited' },
    { symbol: 'METROPOLIS', name: 'Metropolis Healthcare Limited' },
    { symbol: 'MFSL', name: 'Max Financial Services Limited' },
    { symbol: 'MGL', name: 'Mahanagar Gas Limited' },
    { symbol: 'MOTHERSON', name: 'Samvardhana Motherson International Limited' },
    { symbol: 'MPHASIS', name: 'MphasiS Limited' },
    { symbol: 'MRF', name: 'MRF Limited' },
    { symbol: 'MUTHOOTFIN', name: 'Muthoot Finance Limited' },
    { symbol: 'NATIONALUM', name: 'National Aluminium Company Limited' },
    { symbol: 'NAUKRI', name: 'Info Edge (India) Limited' },
    { symbol: 'NAVINFLUOR', name: 'Navin Fluorine International Limited' },
    { symbol: 'NMDC', name: 'NMDC Limited' },
    { symbol: 'NTPC', name: 'NTPC Limited' },
    { symbol: 'OBEROIRLTY', name: 'Oberoi Realty Limited' },
    { symbol: 'OFSS', name: 'Oracle Financial Services Software Limited' },
    { symbol: 'ONGC', name: 'Oil & Natural Gas Corporation Limited' },
    { symbol: 'PEL', name: 'Piramal Enterprises Limited' },
    { symbol: 'PERSISTENT', name: 'Persistent Systems Limited' },
    { symbol: 'PETRONET', name: 'Petronet LNG Limited' },
    { symbol: 'PFC', name: 'Power Finance Corporation Limited' },
    { symbol: 'PIDILITIND', name: 'Pidilite Industries Limited' },
    { symbol: 'PIIND', name: 'PI Industries Limited' },
    { symbol: 'PNB', name: 'Punjab National Bank' },
    { symbol: 'POLYCAB', name: 'Polycab India Limited' },
    { symbol: 'POWERGRID', name: 'Power Grid Corporation of India Limited' },
    { symbol: 'PVRINOX', name: 'PVR INOX Limited' },
    { symbol: 'RAMCOCEM', name: 'The Ramco Cements Limited' },
    { symbol: 'RBLBANK', name: 'RBL Bank Limited' },
    { symbol: 'RECLTD', name: 'REC Limited' },
    { symbol: 'RELIANCE', name: 'Reliance Industries Limited' },
    { symbol: 'SAIL', name: 'Steel Authority of India Limited' },
    { symbol: 'SBIN', name: 'State Bank of India' },
    { symbol: 'SHREECEM', name: 'SHREE CEMENT LIMITED' },
    { symbol: 'SHRIRAMFIN', name: 'Shriram Finance Limited' },
    { symbol: 'SIEMENS', name: 'Siemens Limited' },
    { symbol: 'SRF', name: 'SRF Limited' },
    { symbol: 'SUNPHARMA', name: 'Sun Pharmaceutical Industries Limited' },
    { symbol: 'SUNTV', name: 'Sun TV Network Limited' },
    { symbol: 'SYNGENE', name: 'Syngene International Limited' },
    { symbol: 'TATACHEM', name: 'Tata Chemicals Limited' },
    { symbol: 'TATACOMM', name: 'Tata Communications Limited' },
    { symbol: 'TATACONSUM', name: 'TATA CONSUMER PRODUCTS LIMITED' },
    { symbol: 'TATAMOTORS', name: 'Tata Motors Limited' },
    { symbol: 'TATAPOWER', name: 'Tata Power Company Limited' },
    { symbol: 'TATASTEEL', name: 'Tata Steel Limited' },
    { symbol: 'TCS', name: 'Tata Consultancy Services Limited' },
    { symbol: 'TECHM', name: 'Tech Mahindra Limited' },
    { symbol: 'TITAN', name: 'Titan Company Limited' },
    { symbol: 'TORNTPHARM', name: 'Torrent Pharmaceuticals Limited' },
    { symbol: 'TRENT', name: 'Trent Limited' },
    { symbol: 'TVSMOTOR', name: 'TVS Motor Company Limited' },
    { symbol: 'UBL', name: 'United Breweries Limited' },
    { symbol: 'ULTRACEMCO', name: 'UltraTech Cement Limited' },
    { symbol: 'UNITDSPR', name: 'United Spirits Limited' },
    { symbol: 'UPL', name: 'UPL Limited' },
    { symbol: 'VEDL', name: 'Vedanta Limited' },
    { symbol: 'VOLTAS', name: 'Voltas Limited' },
    { symbol: 'WIPRO', name: 'Wipro Limited' },
    { symbol: 'ZYDUSLIFE', name: 'Zydus Lifesciences Limited' }
];
