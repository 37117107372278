import React, { useEffect, useState } from 'react';
import axios from './AxiosInterceptor';
import Fuse from 'fuse.js';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Tooltip,
  IconButton,
  CircularProgress,
  styled,
  Grid,
  Card,
  CardContent,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip as ChartTooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams, useNavigate } from 'react-router-dom';
import { SYMBOL_COMPANYNAME } from './symbolConstants'; // Import the symbol constants
import config from './config';
import { useMediaQuery } from '@mui/material';

ChartJS.register(Title, ChartTooltip, Legend, BarElement, CategoryScale, LinearScale);

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  // overflow: 'hidden',
  overflowX: 'auto',
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
}));

const TableHeaderStyled = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#1976d2',
  '& th': {
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const WhiteIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
}));

const SearchPaperStyled = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  maxWidth: 400, // Set max width for autosuggestion box
  width: '100%',
}));

const DetailsPage = () => {
  const { symbol } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [fuse, setFuse] = useState(null);
  const [suggestionsOpen, setSuggestionsOpen] = useState(true); // State to control the suggestions visibility
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    axios.get(config.endpoints.priceChanges(symbol, 4))
      .then(response => {
        setData(response.data || []); // Ensure data is not undefined
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [symbol]);

  useEffect(() => {
    if (SYMBOL_COMPANYNAME.length > 0) {
      const fuseInstance = new Fuse(SYMBOL_COMPANYNAME, {
        keys: ['symbol', 'name'],
        includeScore: true,
      });
      setFuse(fuseInstance);
    }
  }, []);

  useEffect(() => {
    if (fuse) {
      const result = fuse.search(query);
      setResults(result.slice(0, 5).map(({ item }) => item));
    }
  }, [query, fuse]);

  const handleChange = (event) => {
    setQuery(event.target.value);
    setSuggestionsOpen(true); // Ensure suggestions are open when query changes
  };

  const handleSelect = (item) => {
    navigate(`/stocks/details/${item.symbol}`);
    setQuery(''); // Clear the query
    setSuggestionsOpen(false); // Close the suggestions
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ mb: 4, padding: 2, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 2 }}>
        {/* Autosuggestion Search Bar */}
        <Box sx={{ position: 'relative', width: isMobile ? '100%' : 300 }}>  {/* Adjust the width here */}
          <TextField
            label="Search a Stock"
            variant="outlined"
            value={query}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          {suggestionsOpen && results.length > 0 && (
            <SearchPaperStyled>
              <List>
                {results.map((item) => (
                  <ListItem button onClick={() => handleSelect(item)} key={item.symbol}>
                    <ListItemText primary={`${item.symbol} - ${item.name}`} />
                  </ListItem>
                ))}
              </List>
            </SearchPaperStyled>
          )}
        </Box>


        <Typography variant="h4" gutterBottom>{symbol} Details</Typography>
        <Box sx={{ mb: 4 }}>
          <Grid container spacing={3}>
            {['Percent Change Close', 'Percent Change High', 'Percent Change Low'].map((label, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h6">
                      {label}
                      <Tooltip title={`${label} = (next day ${label.split(' ')[2].toLowerCase()} - prev day close) / prev day close * 100`}>
                        <IconButton size="small">
                          <HelpOutlineIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Divider />
                    <Box sx={{ height: 400 }}>
                      <Bar
                        data={{
                          labels: data.map(item => item.financial_result_date?.slice(0, 10) || 'Unknown Date'),
                          datasets: [{
                            label: label,
                            data: data.map(item => parseFloat(item[`percent_change_${label.split(' ')[2].toLowerCase()}`]) || 0),
                            backgroundColor: index === 0 ? 'rgba(75, 192, 192, 0.2)' :
                              index === 1 ? 'rgba(153, 102, 255, 0.2)' :
                                'rgba(255, 159, 64, 0.2)',
                            borderColor: index === 0 ? 'rgba(75, 192, 192, 1)' :
                              index === 1 ? 'rgba(153, 102, 255, 1)' :
                                'rgba(255, 159, 64, 1)',
                            borderWidth: 1,
                          }]
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: 'top',
                            },
                            tooltip: {
                              // Use default tooltip behavior
                            }
                          },
                          scales: {
                            x: {
                              ticks: {
                                maxRotation: 90,
                                minRotation: 45,
                              }
                            },
                            y: {
                              beginAtZero: true
                            }
                          }
                        }}
                        height={400}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <TableContainerStyled component={Paper}>
          <Table>
            <TableHeaderStyled>
              <TableRow>
                <TableCellStyled>
                  Financial Result Date
                  <Tooltip title="The date when the financial result was announced">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
                <TableCellStyled>
                  Next Date
                  <Tooltip title="The date following the financial result announcement">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
                <TableCellStyled>
                  Prev Close Price
                  <Tooltip title="The closing price on the day before the financial result announcement">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
                <TableCellStyled>
                  Next High Price
                  <Tooltip title="The highest price on the day after the financial result announcement">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
                <TableCellStyled>
                  Next Low Price
                  <Tooltip title="The lowest price on the day after the financial result announcement">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
                <TableCellStyled>
                  Closing Price
                  <Tooltip title="The closing price on the day of the financial result announcement">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
                <TableCellStyled>
                  % Change Close
                  <Tooltip title="Percentage change in closing price after the announcement">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
                <TableCellStyled>
                  % Change High
                  <Tooltip title="Percentage change in highest price after the announcement">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
                <TableCellStyled>
                  % Change Low
                  <Tooltip title="Percentage change in lowest price after the announcement">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
              </TableRow>
            </TableHeaderStyled>
            <TableBody>
              {data.map((row, index) => (
                <TableRowStyled key={index}>
                  <TableCellStyled>{row.financial_result_date?.slice(0, 10) || 'Unknown Date'}</TableCellStyled>
                  <TableCellStyled>{row.stock_date?.slice(0, 10) || 'Unknown Date'}</TableCellStyled>
                  <TableCellStyled>{parseFloat(row.previous_cls_price)?.toFixed(2) || 'N/A'}</TableCellStyled>
                  <TableCellStyled>{parseFloat(row.trade_high_price)?.toFixed(2) || 'N/A'}</TableCellStyled>
                  <TableCellStyled>{parseFloat(row.trade_low_price)?.toFixed(2) || 'N/A'}</TableCellStyled>
                  <TableCellStyled>{parseFloat(row.closing_price)?.toFixed(2) || 'N/A'}</TableCellStyled>
                  <TableCellStyled sx={{ color: row.percent_change_close != null ? (row.percent_change_close >= 0 ? 'green' : 'red') : 'black' }}>{parseFloat(row.percent_change_close)?.toFixed(2) || 'N/A'}%</TableCellStyled>
                  <TableCellStyled sx={{ color: row.percent_change_high != null ? (row.percent_change_high >= 0 ? 'green' : 'red') : 'black' }}>{parseFloat(row.percent_change_high)?.toFixed(2) || 'N/A'}%</TableCellStyled>
                  <TableCellStyled sx={{ color: row.percent_change_low != null ? (row.percent_change_low >= 0 ? 'green' : 'red') : 'black' }}>{parseFloat(row.percent_change_low)?.toFixed(2) || 'N/A'}%</TableCellStyled>
                </TableRowStyled>
              ))}
            </TableBody>
          </Table>
        </TableContainerStyled>
      </Box>
    </Container>
  );
};

export default DetailsPage;