import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import {
  CssBaseline, AppBar, Toolbar, Typography, Button, Box, IconButton, useMediaQuery,
  Menu, MenuItem, Tooltip, Drawer, List, ListItem, ListItemText
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'; // Correct import
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebase-config';
import ResultMoverPage from './ResultMoverPage';
import DetailsPage from './DetailsPage';
import BudgetStocksPage from './BudgetStocksPage';
import LunarAnalysisPage from './LunarAnalysisPage';
import GannStaticPage from './GannStaticPage';
import LiveMarketCards from './indexRealTime';
import SignIn from './SignIn';
import SignUp from './Signup';
import ForgotPassword from './ForgotPassword';
import TradingJournal from './TradingJournal';
import ViewJournal from './ViewJournal';
import MonthWiseStocksPage from './MonthWiseStocks';
import QuarterlyMovers from './QuarterlyWiseStocks';
import AlertPage from './AlertPage';
import icon from './logo.png'; // Adjust the path as necessary
import NumerologyPage from './NumerologyPage';

function App() {
  const [anchorEl, setAnchorEl] = useState(null);  // Store the anchor element
  const [openMenu, setOpenMenu] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const handleMenuToggle = (event, menu) => {
    setAnchorEl(event.currentTarget); // Set the anchor to the button
    setOpenMenu(openMenu === menu ? '' : menu);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          {isMobile && (
            <IconButton color="inherit" onClick={handleDrawerToggle} edge="start">
              <MenuIcon />
            </IconButton>
          )}

          {/* Responsive Icon Image */}

          <Typography variant={isMobile ? "h6" : "h5"} sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img
              src={icon}
              alt="Logo"
              style={{
                width: '50px', // Default width
                height: '50px', // Default height
                marginRight: '8px', // Spacing between icon and text
              }}
            />
            StockBaaz
          </Typography>

          {!isMobile && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              {/* Stocks Dropdown */}
              <Box>
                <Button color="inherit" onClick={(e) => handleMenuToggle(e, 'stocks')}>
                  Stocks <ArrowDropDown />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={openMenu === 'stocks'}
                  onClose={() => setOpenMenu('')}
                >
                  <MenuItem component="a" href="/stocks">Monthly Movers</MenuItem>
                  <MenuItem component="a" href="/quarterly-movers">Quarterly Movers</MenuItem>
                  <MenuItem component="a" href="/result-movers">Result Movers</MenuItem>
                  <MenuItem component="a" href="/stocks/budget-performance">Budget Movers</MenuItem>
                </Menu>
              </Box>

              {/* Analysis Dropdown */}
              <Box>
                <Button color="inherit" onClick={(e) => handleMenuToggle(e, 'analysis')}>
                  Analysis <ArrowDropDown />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={openMenu === 'analysis'}
                  onClose={() => setOpenMenu('')}
                >
                  <MenuItem component="a" href="/astro/lunar-analysis">Lunar Analysis</MenuItem>
                  <MenuItem component="a" href="/gann/static-dates">Gann Static Dates</MenuItem>
                </Menu>
              </Box>

              <Button color="inherit" href="/indexes">Live Feeds</Button>
              <Button color="inherit" href="/triggers">Alerts</Button>
              <Button color="inherit" href="/numerology">Numerology</Button>
              <Button color="inherit" href="/view-journal">Journal</Button>
            </Box>
          )}

          {user ? (
            <Tooltip title="Log Out">
              <IconButton color="inherit" onClick={handleSignOut}>
                <PowerSettingsNewIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Button color="inherit" href="/signin">Sign In</Button>
          )}
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          <IconButton onClick={handleDrawerToggle} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon />
          </IconButton>
          <List>
          <ListItem button component="a" href="/triggers">
              <ListItemText primary="Alerts" />
            </ListItem>
          <ListItem button component="a" href="/indexes">
              <ListItemText primary="Live Feeds" />
            </ListItem>
            <ListItem button component="a" href="/stocks">
              <ListItemText primary="Monthly Movers" />
            </ListItem>
            <ListItem button component="a" href="/quarterly-movers">
              <ListItemText primary="Quarterly Movers" />
            </ListItem>
            <ListItem button component="a" href="/result-movers">
              <ListItemText primary="Result Movers" />
            </ListItem>
            <ListItem button component="a" href="/stocks/budget-performance">
              <ListItemText primary="Budget Movers" />
            </ListItem>
            <ListItem button component="a" href="/gann/static-dates">
              <ListItemText primary="Gann Static Dates" />
            </ListItem>
            <ListItem button component="a" href="/astro/lunar-analysis">
              <ListItemText primary="Lunar Analysis" />
            </ListItem>
            <ListItem button component="a" href="/numerology">
              <ListItemText primary="Numerology" />
            </ListItem>

            <ListItem button component="a" href="/view-journal">
              <ListItemText primary="Journal" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* Routes */}
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/" element={<MonthWiseStocksPage />} />
            <Route path="/stocks" element={<MonthWiseStocksPage />} />
            <Route path="/quarterly-movers" element={<QuarterlyMovers />} />
            <Route path="/result-movers" element={<ResultMoverPage />} />
            <Route path="/stocks/details/:symbol" element={<DetailsPage />} />
            <Route path="/stocks/budget-performance" element={<BudgetStocksPage />} />
            <Route path="/astro/lunar-analysis" element={<LunarAnalysisPage />} />
            <Route path="/gann/static-dates" element={<GannStaticPage />} />
            <Route path="/numerology" element={<NumerologyPage />} />
        
        {user ? (
          <> 
            <Route path="/indexes" element={<LiveMarketCards />} />
            <Route path="/create-journal" element={<TradingJournal />} />
            <Route path="/view-journal" element={<ViewJournal />} />
            <Route path="/triggers" element={<AlertPage />} />
          </>
        ) : (
          <Route path="*" element={<SignIn />} />
        )}
      </Routes>
    </>
  );
}

export default App;
