import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase-config';
import { Button, TextField, Typography, Container, Box, CircularProgress, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const theme = useTheme();

  const handleResetPassword = async () => {
    setLoading(true);
    setError(null);
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess('Password reset email sent! Check your inbox.');
    } catch (error) {
      console.error('Error sending reset email:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
      <Box sx={{
        textAlign: 'center',
        padding: 4,
        borderRadius: 5,
        boxShadow: 10,
        borderColor: 'transparent',
        // background: 'linear-gradient(to right, #d4d4d4, #f9f9f9)',
        width: '100%',
      }}>
        <Typography variant="h5" gutterBottom>
          Forgot Password
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ backgroundColor: '#fff', borderRadius: 1 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetPassword}
          sx={{ marginTop: 2, fontSize: '0.8rem', padding: '10px 20px' }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Send Reset Email'}
        </Button>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body2">
            <RouterLink to="/signIn" style={{ color: theme.palette.primary.main }}>
              Back to Sign In
            </RouterLink>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
