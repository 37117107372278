import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, Grid, TextField, Card, CardContent, Chip, Box, Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import config from './config';
import axios from './AxiosInterceptor';
import { borderRadius } from '@mui/system';

const predefinedMistakes = ["FOMO entry", "Averaging", "Incorrect stop loss", "Overtraded", "Missed entry", "Random Trades", "Emotional control", "Other"];
const predefinedImprovements = ["Better risk management", "Stick to the plan", "Patience", "Emotional control", "Avoid FOMO", "Other"];
const predefinedLearnings = ["Strategy refinement", "Market understanding", "Emotional control", "Risk management", "Other"];
const tradeTypes = ["Swing", "Scalp", "Option Buy", "Option Sell", "Stocks", "Fut Buy", "Fut Sell", "Breakout/Breakdown Trades", "intrday stocks", "Other"];
const profitLossOptions = ["Profit", "Loss"];

const TradingJournal = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [mistakeTags, setMistakeTags] = useState([]);
    const [improvementTags, setImprovementTags] = useState([]);
    const [learningTags, setLearningTags] = useState([]);
    const [customMistake, setCustomMistake] = useState('');
    const [customImprovement, setCustomImprovement] = useState('');
    const [customLearning, setCustomLearning] = useState('');
    const [profitLossType, setProfitLossType] = useState('');
    const [amount, setAmount] = useState('');
    const [tradePlan, setTradePlan] = useState([]);
    const [customTradePlan, setCustomTradePlan] = useState('');
    const [tradeDescription, setTradeDescription] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    useEffect(() => {
        if (state?.journal) {
            const {
                trade_plan = [], mistakes = [], learnings = [],
                improvements = [], profit_loss = '', amount = '', trade_description = ''
            } = state.journal;

            setTradePlan(Array.isArray(trade_plan) ? trade_plan : []);
            setMistakeTags(Array.isArray(mistakes) ? mistakes : []);
            setLearningTags(Array.isArray(learnings) ? learnings : []);
            setImprovementTags(Array.isArray(improvements) ? improvements : []);
            setProfitLossType(profit_loss || '');
            setAmount(amount.toString() || '');
            setTradeDescription(trade_description || '');

            // Set custom values if "Other" is included
            if (trade_plan.includes("Other")) {
                setCustomTradePlan(trade_plan.find(plan => plan !== "Other"));
            }
            if (mistakes.includes("Other")) {
                setCustomMistake(mistakes.find(mistake => mistake !== "Other"));
            }
            if (learnings.includes("Other")) {
                setCustomLearning(learnings.find(learning => learning !== "Other"));
            }
            if (improvements.includes("Other")) {
                setCustomImprovement(improvements.find(improvement => improvement !== "Other"));
            }
        }
    }, [state]);


    const handleTagClick = (tag, setTags, tags, setCustomTag) => {
        if (tag === "Other") {
            setTags(prev => prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]);
        } else {
            setTags(prev => prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]);
            if (tags.includes("Other")) setCustomTag('');
        }
    };

    const handleTradePlanClick = (type) => {
        if (type === "Other") {
            setTradePlan(prev => prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]);
        } else {
            setTradePlan(prev => prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]);
            if (tradePlan.includes("Other")) setCustomTradePlan('');
        }
    };

    const handleProfitLossChange = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) { // Allow only numeric input
            setAmount(value);
        }
    };

    const handleSave = async () => {
        const offset = selectedDate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(selectedDate.getTime() - offset);

        // Prepare the data to be sent to the server
        const data = {
            date: adjustedDate.toISOString().split('T')[0],
            trade_plan: tradePlan.includes("Other") ? [...tradePlan.filter(plan => plan !== "Other"), customTradePlan] : tradePlan,
            mistakes: mistakeTags.includes("Other") ? [...mistakeTags.filter(tag => tag !== "Other"), customMistake] : mistakeTags,
            learnings: learningTags.includes("Other") ? [...learningTags.filter(tag => tag !== "Other"), customLearning] : learningTags,
            improvements: improvementTags.includes("Other") ? [...improvementTags.filter(tag => tag !== "Other"), customImprovement] : improvementTags,
            profit_loss: profitLossType,
            amount: parseFloat(amount) || 0,
            trade_description: tradeDescription,
        };

        try {
            if (state?.journal) {
                // Update existing journal entry
                const response = await axios.put(config.endpoints.tradingJournal(), data, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 200) navigate('/view-journal');
                else console.error('Failed to update journal entry:', response.statusText);
            } else {
                // Create a new journal entry
                const response = await axios.post(config.endpoints.tradingJournal(), data, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 201) navigate('/view-journal');
                else console.error('Failed to save journal entry:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    Trading Journal
                </Typography>

                {/* Date Picker */}
                <Box sx={{ mb: 4, display: 'flex', justifyContent: 'flex-start' }}>
                    <DatePicker
                        label="Journal Date"
                        value={selectedDate}
                        onChange={(newValue) => setSelectedDate(newValue)}
                        renderInput={(params) => <TextField {...params} size="small" sx={{ width: '200px' }} />}
                    />
                </Box>

                <Grid container spacing={4}>
                    {/* Trade Plan */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: 6, height: '260px', display: 'flex', flexDirection: 'column', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)', boxShadow: 12 } }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" gutterBottom>Trade Plan</Typography>
                                <Box>
                                    {tradeTypes.map(type => (
                                        <Chip key={type} label={type} onClick={() => handleTradePlanClick(type)} color={tradePlan.includes(type) ? "primary" : "default"} sx={{ margin: 0.5 }} />
                                    ))}
                                </Box>
                                {tradePlan.includes("Other") && (
                                    <TextField label="Describe your trade type..." variant="outlined" fullWidth margin="normal" value={customTradePlan} onChange={(e) => setCustomTradePlan(e.target.value)} inputProps={{ maxLength: 100 }} />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Trades Description */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: 6, height: '260px', display: 'flex', flexDirection: 'column', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)', boxShadow: 12 } }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" gutterBottom>Trades Description</Typography>
                                <TextField label="Describe your trades..." multiline rows={4} variant="outlined" fullWidth inputProps={{ maxLength: 100 }} value={tradeDescription} onChange={(e) => setTradeDescription(e.target.value)} />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Mistakes */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: 6, height: '260px', display: 'flex', flexDirection: 'column', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)', boxShadow: 12 } }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" gutterBottom>Mistakes</Typography>
                                <Box>
                                    {predefinedMistakes.map(tag => (
                                        <Chip key={tag} label={tag} onClick={() => handleTagClick(tag, setMistakeTags, mistakeTags, setCustomMistake)} color={mistakeTags.includes(tag) ? "primary" : "default"} sx={{ margin: 0.5 }} />
                                    ))}
                                </Box>
                                {mistakeTags.includes("Other") && (
                                    <TextField label="Describe your mistake..." variant="outlined" fullWidth margin="normal" value={customMistake} onChange={(e) => setCustomMistake(e.target.value)} inputProps={{ maxLength: 100 }} />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Improvements */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: 6, height: '260px', display: 'flex', flexDirection: 'column', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)', boxShadow: 12 } }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" gutterBottom>Improvements</Typography>
                                <Box>
                                    {predefinedImprovements.map(tag => (
                                        <Chip key={tag} label={tag} onClick={() => handleTagClick(tag, setImprovementTags, improvementTags, setCustomImprovement)} color={improvementTags.includes(tag) ? "primary" : "default"} sx={{ margin: 0.5 }} />
                                    ))}
                                </Box>
                                {improvementTags.includes("Other") && (
                                    <TextField label="Describe your improvement..." variant="outlined" fullWidth margin="normal" value={customImprovement} onChange={(e) => setCustomImprovement(e.target.value)} inputProps={{ maxLength: 100 }} />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Learnings */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: 6, height: '260px', display: 'flex', flexDirection: 'column', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)', boxShadow: 12 } }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" gutterBottom>Learnings</Typography>
                                <Box>
                                    {predefinedLearnings.map(tag => (
                                        <Chip key={tag} label={tag} onClick={() => handleTagClick(tag, setLearningTags, learningTags, setCustomLearning)} color={learningTags.includes(tag) ? "primary" : "default"} sx={{ margin: 0.5 }} />
                                    ))}
                                </Box>
                                {learningTags.includes("Other") && (
                                    <TextField label="Describe your learning..." variant="outlined" fullWidth margin="normal" value={customLearning} onChange={(e) => setCustomLearning(e.target.value)} inputProps={{ maxLength: 100 }} />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Profit/Loss & Amount */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: 6, height: '260px', display: 'flex', flexDirection: 'column', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)', boxShadow: 12 } }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" gutterBottom>Profit/Loss</Typography>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel>Profit/Loss</InputLabel>
                                    <Select value={profitLossType} label="Profit/Loss" onChange={(e) => setProfitLossType(e.target.value)}>
                                        {profitLossOptions.map(option => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField label="Amount" variant="outlined" fullWidth margin="normal" value={amount} onChange={handleProfitLossChange} inputProps={{ maxLength: 100 }} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Save Button */}
                <Box mt={4} sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSave}
                        sx={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            // fontWeight: 'bold',
                            boxShadow: 3,
                            transition: 'background-color 0.3s ease-in-out',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                                boxShadow: 6,
                            },
                        }}
                    >
                        Save Journal Entry
                    </Button>
                </Box>
            </Container>
        </LocalizationProvider>
    );
};

export default TradingJournal;
