import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase-config';
import { Button, TextField, Typography, Container, Box, CircularProgress, Link, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const navigate = useNavigate();

    const handleSignIn = async () => {
        setLoading(true);
        setError(null);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log(userCredential);
            const token = await userCredential.user.getIdToken();
	    document.cookie = `authToken=${token}; path=/;`;
            navigate('/stocks')
        } catch (error) {
            console.error('Error signing in:', error);
            setError(error.code);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Box sx={{
                textAlign: 'center',
                padding: { xs: 3, sm: 4 }, // Adjust padding for mobile and larger screens
                borderRadius: 5,
                boxShadow: 10,
                width: '100%',
                maxWidth: '400px' // Set a max-width for larger screens
            }}>
                <Typography variant="h5" gutterBottom>
                    Sign In
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignIn}
                    sx={{ marginTop: 2, fontSize: { xs: '0.8rem', sm: '1rem' }, padding: '10px 20px' }}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Sign In'}
                </Button>
                <Box sx={{ marginTop: 2 }}>
                    <Typography variant="body2">
                        <Link component={RouterLink} to="/forgot-password" variant="body2">
                            Forgot Password?
                        </Link>
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                        Don't have an account?{' '}
                        <Link component={RouterLink} to="/signUp" variant="body2">
                            Sign Up
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default SignIn;
