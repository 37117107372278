// import React, { useEffect, useState } from 'react';
// import axios from './AxiosInterceptor';
// import { Container, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, FormControl, InputLabel, Select, MenuItem, Tabs, Tab, CircularProgress, Tooltip, IconButton } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import config from './config';  // Import the config file

// const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[4],
//   overflow: 'hidden',
//   marginTop: theme.spacing(2),
//   border: `1px solid ${theme.palette.divider}`,
// }));

// const TableHeaderStyled = styled(TableHead)(({ theme }) => ({
//   backgroundColor: '#1976d2',
//   '& th': {
//     fontWeight: 'bold',
//     color: theme.palette.common.white,
//   },
// }));

// const TableCellStyled = styled(TableCell)(({ theme }) => ({
//   borderBottom: `1px solid ${theme.palette.divider}`,
//   padding: theme.spacing(1),
// }));

// const TableRowStyled = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
//   '&:hover': {
//     backgroundColor: theme.palette.action.selected,
//   },
// }));

// const WhiteIconButton = styled(IconButton)(({ theme }) => ({
//   color: 'white',
// }));


// const Title = styled(Typography)(({ theme }) => ({
//   fontSize: '1.5rem',
//   fontWeight: 600,
//   color: theme.palette.text.primary,
//   marginBottom: theme.spacing(2),
//   textTransform: 'uppercase',
// }));

// const gannStaticDates = [
//   '07-Jan', '19-Jan', '03-Feb', '18-Feb', '21-Mar', '20-Apr',
//   '05-May', '20-May', '02-Jun', '20-Jun', '20-Jul', '04-Aug',
//   '20-Aug', '19-Sep', '20-Oct', '04-Nov', '19-Nov', '01-Dec',
//   '19-Dec'
// ];

// const GannStaticPage = () => {
//   const [data, setData] = useState([]);
//   const [index, setIndex] = useState('nifty');  // Default index value
//   const [limit, setLimit] = useState(5);        // Default limit value
//   const [dayMonth, setDayMonth] = useState('07-Jan'); // Default value
//   const [loading, setLoading] = useState(true);
//   const [tabValue, setTabValue] = useState('nifty'); // Default tab value

//   useEffect(() => {
//     setLoading(true);
//     axios.get(config.endpoints.gannDates(index, dayMonth, limit))
//       .then(response => {
//         setData(response.data);
//         setLoading(false);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//         setLoading(false);
//       });
//   }, [index, dayMonth, limit]); // Ensure dependencies include index, dayMonth, and limit

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//     setIndex(newValue); // Update the index based on selected tab
//   };

//   const handleDayMonthChange = (event) => {
//     setDayMonth(event.target.value);
//   };

//   const handleLimitChange = (event) => {
//     setLimit(event.target.value);
//   };

//   if (loading) {
//     return <CircularProgress />;
//   }

//   return (
//     <Container maxWidth="lg" sx={{ mt: 4 }}>
//       <Box sx={{ mb: 4, padding: 2, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 2 }}>
//         <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
//         Gann Dates Analysis
//         </Typography>
//         <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
//           <FormControl sx={{ minWidth: 120 }}>
//             <InputLabel id="dayMonth-select-label">Day/Month</InputLabel>
//             <Select
//               labelId="dayMonth-select-label"
//               id="dayMonth-select"
//               value={dayMonth}
//               onChange={handleDayMonthChange}
//               label="Day/Month"
//             >
//               {gannStaticDates.map(date => (
//                 <MenuItem key={date} value={date}>{date}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           <FormControl sx={{ minWidth: 120 }}>
//             <InputLabel id="limit-select-label">Limit</InputLabel>
//             <Select
//               labelId="limit-select-label"
//               id="limit-select"
//               value={limit}
//               onChange={handleLimitChange}
//               label="Limit"
//             >
//               <MenuItem value={5}>5</MenuItem>
//               <MenuItem value={10}>10</MenuItem>
//             </Select>
//           </FormControl>
//         </Box>
//         <Tabs value={tabValue} onChange={handleTabChange} aria-label="Gann Dates Tabs">
//           <Tab label="Nifty" value="nifty" />
//           <Tab label="Bank Nifty" value="bankNifty" />
//           {/* Add more tabs if needed */}
//         </Tabs>
//         <TableContainerStyled component={Paper}>
//           <Table>
//             <TableHeaderStyled>
//               <TableRow>
//                 <TableCellStyled>
//                   Event Date
//                   <Tooltip title="The date of the event">
//                     <WhiteIconButton size="small">
//                       <VisibilityIcon fontSize="small" />
//                     </WhiteIconButton>
//                   </Tooltip>
//                 </TableCellStyled>
//                 <TableCellStyled>
//                   Previous Date
//                   <Tooltip title="Date before the event">
//                     <WhiteIconButton size="small">
//                       <VisibilityIcon fontSize="small" />
//                     </WhiteIconButton>
//                   </Tooltip>
//                 </TableCellStyled>
//                 <TableCellStyled>
//                   Previous Close Price
//                   <Tooltip title="Close price on the previous date">
//                     <WhiteIconButton size="small">
//                       <VisibilityIcon fontSize="small" />
//                     </WhiteIconButton>
//                   </Tooltip>
//                 </TableCellStyled>
//                 <TableCellStyled>
//                   Next Date
//                   <Tooltip title="Date after the event">
//                     <WhiteIconButton size="small">
//                       <VisibilityIcon fontSize="small" />
//                     </WhiteIconButton>
//                   </Tooltip>
//                 </TableCellStyled>
//                 <TableCellStyled>
//                   Next Date Close Price
//                   <Tooltip title="Close price on the next date">
//                     <WhiteIconButton size="small">
//                       <VisibilityIcon fontSize="small" />
//                     </WhiteIconButton>
//                   </Tooltip>
//                 </TableCellStyled>
//                 <TableCellStyled>
//                   % Change
//                   <Tooltip title="Percentage change between the next and previous close prices">
//                     <WhiteIconButton size="small">
//                       <VisibilityIcon fontSize="small" />
//                     </WhiteIconButton>
//                   </Tooltip>
//                 </TableCellStyled>
//               </TableRow>
//             </TableHeaderStyled>
//             <TableBody>
//               {data.map((item, index) => (
//                 <TableRowStyled key={index}>
//                   <TableCellStyled>{item.event_date ? new Date(item.event_date).toLocaleDateString() : 'N/A'}</TableCellStyled>
//                   <TableCellStyled>{item.previous_date ? new Date(item.previous_date).toLocaleDateString() : 'N/A'}</TableCellStyled>
//                   <TableCellStyled>{item.previous_close_price != null ? item.previous_close_price.toFixed(2) : 'N/A'}</TableCellStyled>
//                   <TableCellStyled>{item.second_day_date ? new Date(item.second_day_date).toLocaleDateString() : 'N/A'}</TableCellStyled>
//                   <TableCellStyled>{item.second_day_close_price != null ? item.second_day_close_price.toFixed(2) : 'N/A'}</TableCellStyled>
//                   <TableCellStyled sx={{ color: item.percentage_change != null ? (item.percentage_change >= 0 ? 'green' : 'red') : 'black' }}>
//                     {item.percentage_change != null ? item.percentage_change.toFixed(2) + '%' : 'N/A'}
//                   </TableCellStyled>
//                 </TableRowStyled>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainerStyled>
//       </Box>
//     </Container>
//   );
// };

// export default GannStaticPage;



import React, { useEffect, useState } from 'react';
import axios from './AxiosInterceptor';
import {
  Container, Typography, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Box, FormControl, InputLabel, Select, MenuItem, Tabs, Tab,
  CircularProgress, Tooltip, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import config from './config';  // Import the config file

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  overflowX: 'auto', // Ensures horizontal scrolling for small screens
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
}));

const TableHeaderStyled = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#1976d2',
  '& th': {
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const WhiteIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
}));

const gannStaticDates = [
  '07-Jan', '19-Jan', '03-Feb', '18-Feb', '21-Mar', '20-Apr',
  '05-May', '20-May', '02-Jun', '20-Jun', '20-Jul', '04-Aug',
  '20-Aug', '19-Sep', '20-Oct', '04-Nov', '19-Nov', '01-Dec',
  '19-Dec'
];

const GannStaticPage = () => {
  const [data, setData] = useState([]);
  const [index, setIndex] = useState('nifty');  // Default index value
  const [limit, setLimit] = useState(5);        // Default limit value
  const [dayMonth, setDayMonth] = useState('07-Jan'); // Default value
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState('nifty'); // Default tab value

  useEffect(() => {
    setLoading(true);
    axios.get(config.endpoints.gannDates(index, dayMonth, limit))
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [index, dayMonth, limit]); // Ensure dependencies include index, dayMonth, and limit

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setIndex(newValue); // Update the index based on selected tab
  };

  const handleDayMonthChange = (event) => {
    setDayMonth(event.target.value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ mb: 4, padding: 2, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
          Gann Dates Analysis
        </Typography>

        {/* Filters section */}
        <Box sx={{ mb: 2, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="dayMonth-select-label">Day/Month</InputLabel>
            <Select
              labelId="dayMonth-select-label"
              id="dayMonth-select"
              value={dayMonth}
              onChange={handleDayMonthChange}
              label="Day/Month"
            >
              {gannStaticDates.map(date => (
                <MenuItem key={date} value={date}>{date}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="limit-select-label">Limit</InputLabel>
            <Select
              labelId="limit-select-label"
              id="limit-select"
              value={limit}
              onChange={handleLimitChange}
              label="Limit"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Tabs */}
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Gann Dates Tabs">
          <Tab label="Nifty" value="nifty" />
          <Tab label="Bank Nifty" value="bankNifty" />
          {/* Add more tabs if needed */}
        </Tabs>

        {/* Table */}
        <TableContainerStyled component={Paper}>
          <Table>
            <TableHeaderStyled>
              <TableRow>
                <TableCellStyled>
                  Event Date
                  <Tooltip title="The date of the event">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
                <TableCellStyled>
                  Previous Date
                  <Tooltip title="Date before the event">
                    <WhiteIconButton size="small">
                      <VisibilityIcon fontSize="small" />
                    </WhiteIconButton>
                  </Tooltip>
                </TableCellStyled>
                <TableCellStyled>Previous Close Price</TableCellStyled>
                <TableCellStyled>% Change</TableCellStyled>
                <TableCellStyled>Next Date</TableCellStyled>
                <TableCellStyled>Next Close Price</TableCellStyled>
              </TableRow>
            </TableHeaderStyled>
            <TableBody>
              {data.map((item, index) => (
                <TableRowStyled key={index}>
                  <TableCellStyled>{new Date(item.event_date).toLocaleDateString()}</TableCellStyled>
                  <TableCellStyled>{new Date(item.previous_date).toLocaleDateString()}</TableCellStyled>
                  <TableCellStyled>{item.previous_close_price?.toFixed(2) ?? 'N/A'}</TableCellStyled>
                  <TableCellStyled sx={{ color: item.percentage_change >= 0 ? 'green' : 'red' }}>
                    {item.percentage_change !== null ? `${item.percentage_change.toFixed(2)}%` : 'N/A'}
                  </TableCellStyled>
                  <TableCellStyled>{new Date(item.second_day_date).toLocaleDateString()}</TableCellStyled>
                  <TableCellStyled>{item.second_day_close_price?.toFixed(2) ?? 'N/A'}</TableCellStyled>
                </TableRowStyled>
              ))}
            </TableBody>
          </Table>
        </TableContainerStyled>
      </Box>
    </Container>
  );
};

export default GannStaticPage;
