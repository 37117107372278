import React, { useEffect, useState } from 'react';
import axios from './AxiosInterceptor';
import {
  Container, Typography, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Box, Tooltip, IconButton, Select, MenuItem, InputLabel,
  FormControl, Tabs, Tab, CircularProgress, useMediaQuery, useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import config from './config';  // Import the config file

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  overflowX: 'auto', // Add horizontal scrolling for small screens
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
}));

const TableHeaderStyled = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#1976d2',
  '& th': {
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const WhiteIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
}));

const LunarAnalysisPage = () => {
  const [data, setData] = useState([]);
  const [type, setType] = useState('amavasya');  // Default value
  const [limit, setLimit] = useState(10);        // Default value
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState('nifty');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screen

  useEffect(() => {
    setLoading(true);
    axios.get(config.endpoints.lunarAnalysis(tabValue, type, limit))
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [tabValue, type, limit]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ mb: 4, padding: 2, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 2 }}>
        <Typography variant={isMobile ? "h6" : "h4"} gutterBottom>Lunar Event Trading Analysis</Typography>

        {/* Filters section - Adjusted for mobile */}
        <Box sx={{ mb: 2, display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={type}
              onChange={handleTypeChange}
              label="Type"
            >
              <MenuItem value="amavasya">Amavasya</MenuItem>
              <MenuItem value="fullmoon">Full Moon</MenuItem>
              <MenuItem value="solstice">Solstice</MenuItem>
              <MenuItem value="equinox">Equinox</MenuItem>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="limit-select-label">Limit</InputLabel>
            <Select
              labelId="limit-select-label"
              id="limit-select"
              value={limit}
              onChange={handleLimitChange}
              label="Limit"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Tabs */}
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant={isMobile ? "scrollable" : "standard"}
          scrollButtons={isMobile ? "auto" : false}
          aria-label="Lunar Analysis Tabs"
        >
          <Tab label="Nifty" value="nifty" />
          <Tab label="Bank Nifty" value="bankNifty" />
        </Tabs>

        {/* Table */}
        <TableContainerStyled component={Paper}>
          <Table>
            <TableHeaderStyled>
              <TableRow>
                <TableCellStyled>
                  Event Date
                  {!isMobile && (
                    <Tooltip title="The date on which the lunar event occurs">
                      <WhiteIconButton size="small">
                        <VisibilityIcon fontSize="small" />
                      </WhiteIconButton>
                    </Tooltip>
                  )}
                </TableCellStyled>
                <TableCellStyled>% Change</TableCellStyled>
                <TableCellStyled>Prev Date</TableCellStyled>
                <TableCellStyled>Prev Close Price</TableCellStyled>
                <TableCellStyled>Next Date</TableCellStyled>
                <TableCellStyled>Next Date Close Price</TableCellStyled>
              </TableRow>
            </TableHeaderStyled>
            <TableBody>
              {data.map((item, index) => (
                <TableRowStyled key={index}>
                  <TableCellStyled>{new Date(item.event_date).toLocaleDateString()}</TableCellStyled>
                  <TableCellStyled sx={{ color: item.percentage_change >= 0 ? 'green' : 'red' }}>
                    {item.percentage_change !== null ? `${item.percentage_change.toFixed(2)}%` : 'N/A'}
                  </TableCellStyled>
                  <TableCellStyled>{new Date(item.previous_date).toLocaleDateString()}</TableCellStyled>
                  <TableCellStyled>{item.previous_close_price?.toFixed(2) ?? 'N/A'}</TableCellStyled>
                  <TableCellStyled>{new Date(item.second_day_date).toLocaleDateString()}</TableCellStyled>
                  <TableCellStyled>{item.second_day_close_price?.toFixed(2) ?? 'N/A'}</TableCellStyled>
                </TableRowStyled>
              ))}
            </TableBody>
          </Table>
        </TableContainerStyled>
      </Box>
    </Container>
  );
};

export default LunarAnalysisPage;
