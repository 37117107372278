import React, { useEffect, useState } from 'react';
import axios from './AxiosInterceptor';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tabs,
  Tab
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import config from './config'; // Import the config file

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  overflow: 'hidden',
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
}));

const TableHeaderStyled = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#1976d2',
  '& th': {
    fontWeight: 'bold',
    color: theme.palette.common.white,
    fontSize: { xs: '0.8rem', sm: '1rem' }, // Responsive font size
  },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  fontSize: { xs: '0.75rem', sm: '1rem' }, // Responsive font size for table cells
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const BlackIconButton = styled(IconButton)(({ theme }) => ({
  color: 'black',
}));

const BudgetStocksPage = () => {
  const [data, setData] = useState({ bullish: [], bearish: [] });
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [tab, setTab] = useState('bullish');
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios.get(config.endpoints.budgetStocks(tab, limit))
      .then(response => {
        setData(prevData => ({
          ...prevData,
          [tab]: response.data || []
        }));
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [tab, limit]);

  const handleRowClick = (symbol) => {
    navigate(`/stocks/details/${symbol}`);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const currentData = data[tab] || [];

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ mb: 4, padding: 2, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} // Responsive heading size
        >
          Budget Stocks - {tab.charAt(0).toUpperCase() + tab.slice(1)}
        </Typography>

        <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="limit-select-label">Top</InputLabel>
            <Select
              labelId="limit-select-label"
              id="limit-select"
              value={limit}
              onChange={handleLimitChange}
              label="Top"
            >
              <MenuItem value={5}>Top 5</MenuItem>
              <MenuItem value={10}>Top 10</MenuItem>
              <MenuItem value={20}>Top 20</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="stock performance tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Bullish" value="bullish" />
          <Tab label="Bearish" value="bearish" />
        </Tabs>

        <TableContainerStyled component={Paper}>
          <Box sx={{ overflowX: 'auto' }}>
            <Table>
              <TableHeaderStyled>
                <TableRow>
                  <TableCellStyled>Symbol</TableCellStyled>
                  <TableCellStyled>% Avg Change</TableCellStyled>
                  <TableCellStyled>% Change 2024</TableCellStyled>
                  <TableCellStyled>% Change 2023</TableCellStyled>
                  <TableCellStyled>% Change 2022</TableCellStyled>
                  <TableCellStyled>% Change 2021</TableCellStyled>
                </TableRow>
              </TableHeaderStyled>

              <TableBody>
                {currentData.map((item, index) => (
                  <TableRowStyled key={index}>
                    <TableCellStyled>{item.symbol}</TableCellStyled>
                    <TableCellStyled sx={{ color: parseFloat(item.average_change) < 0 ? 'red' : 'green' }}>
                      {isNaN(parseFloat(item.average_change)) ? 'N/A' : parseFloat(item.average_change).toFixed(2)}
                    </TableCellStyled>
                    <TableCellStyled sx={{ color: parseFloat(item.change_2024) < 0 ? 'red' : 'green' }}>
                      {isNaN(parseFloat(item.change_2024)) ? 'N/A' : parseFloat(item.change_2024).toFixed(2)}
                    </TableCellStyled>
                    <TableCellStyled sx={{ color: parseFloat(item.change_2023) < 0 ? 'red' : 'green' }}>
                      {isNaN(parseFloat(item.change_2023)) ? 'N/A' : parseFloat(item.change_2023).toFixed(2)}
                    </TableCellStyled>
                    <TableCellStyled sx={{ color: parseFloat(item.change_2022) < 0 ? 'red' : 'green' }}>
                      {isNaN(parseFloat(item.change_2022)) ? 'N/A' : parseFloat(item.change_2022).toFixed(2)}
                    </TableCellStyled>
                    <TableCellStyled sx={{ color: parseFloat(item.change_2021) < 0 ? 'red' : 'green' }}>
                      {isNaN(parseFloat(item.change_2021)) ? 'N/A' : parseFloat(item.change_2021).toFixed(2)}
                    </TableCellStyled>
		  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </Box>
        </TableContainerStyled>
      </Box>
    </Container>
  );
};

export default BudgetStocksPage;
