import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, Button, Box, Select, MenuItem, FormControl, InputLabel, TextField, IconButton, Menu } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from './AxiosInterceptor';
import config from './config';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, startOfDay } from 'date-fns';

// Utility function to determine if the device is mobile
const isMobile = () => window.innerWidth <= 600;

const timePeriods = [
    { label: 'Previous 3 Days', value: '3' },
    { label: 'Previous 1 Week', value: '7' },
    { label: 'Previous 15 Days', value: '15' },
];

const ViewJournal = () => {
    const [timePeriod, setTimePeriod] = useState('3');
    const [selectedDate, setSelectedDate] = useState(null);
    const [entries, setEntries] = useState([]);
    const [noEntriesMessage, setNoEntriesMessage] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedEntry, setSelectedEntry] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEntries = async () => {
            try {
                setEntries([]);
                setNoEntriesMessage('');

                const dateParam = selectedDate ? `&date=${format(startOfDay(selectedDate), 'yyyy-MM-dd')}` : '';
                const response = await axios.get(`${config.endpoints.tradingJournal()}?days=${timePeriod}${dateParam}`);

                if (response.data.length === 0) {
                    setNoEntriesMessage('No journal entries found for the selected date.');
                } else {
                    setEntries(response.data);
                }
            } catch (error) {
                setNoEntriesMessage('No Journal entries for the period.');
            }
        };

        fetchEntries();
    }, [timePeriod, selectedDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date ? startOfDay(date) : null);
        setTimePeriod(''); // Reset time period when date is selected
    };

    const handleTimePeriodChange = (e) => {
        setTimePeriod(e.target.value);
        setSelectedDate(null); // Reset date when time period is selected
    };

    const safeJoin = (arr) => (Array.isArray(arr) ? arr.join(', ') : 'N/A');

    const handleMenuOpen = (event, entry) => {
        setAnchorEl(event.currentTarget);
        setSelectedEntry(entry);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedEntry(null);
    };

    const handleEditClick = () => {
        if (selectedEntry) {
            navigate('/create-journal', { state: { journal: selectedEntry } });
        }
        handleMenuClose();
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    mb: 4
                }}
            >
                <Typography variant="h4" component="h1">
                    View Journal
                </Typography>
                <Box
                    sx={{
                        mt: 2, // Add some margin above the button
                        width: isMobile() ? '100%' : 'auto' // Full width on mobile, auto on larger screens
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => navigate('/create-journal')}
                        sx={{
                            fontSize: 'small',
                            borderRadius: '8px',
                            boxShadow: 3,
                            transition: 'background-color 0.3s ease-in-out, transform 0.2s ease',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                                boxShadow: 6,
                                transform: 'scale(1.05)',
                            },
                        }}
                    >
                        Create Entry
                    </Button>
                </Box>
            </Box>

            <Box sx={{ mb: 4, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, alignItems: 'center', width: '100%' }}>
                <FormControl
                    variant="outlined"
                    sx={{
                        width: isMobile() ? '100%' : '200px'  // Full width on mobile, fixed width on larger screens
                    }}
                >
                    <InputLabel>Time Period</InputLabel>
                    <Select
                        value={timePeriod}
                        onChange={handleTimePeriodChange}
                        label="Time Period"
                    >
                        {timePeriods.map(period => (
                            <MenuItem key={period.value} value={period.value}>
                                {period.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                sx={{
                                    width: isMobile() ? '100%' : '200px'  // Full width on mobile, fixed on larger screens
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Box>

            {noEntriesMessage ? (
                <Typography variant="h6" color="error" align="center">
                    {noEntriesMessage}
                </Typography>
            ) : (
                <Grid container spacing={4}>
                    {entries.map((entry, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: '100%',
                                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                                    transition: 'transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out',
                                    mb: 2,
                                    '&:hover': {
                                        transform: 'perspective(1200px)  rotateY(6deg) scale(1.05)',
                                        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.3)',
                                    },
                                }}
                            >
                                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                        <Typography variant="h6">
                                            Date: {new Date(entry.date).toLocaleDateString()}
                                        </Typography>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls={`menu-${index}`}
                                            aria-haspopup="true"
                                            onClick={(event) => handleMenuOpen(event, entry)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id={`menu-${index}`}
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                                        </Menu>
                                    </Box>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        <strong>Trade Type:</strong> {safeJoin(entry.trade_plan)}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            mb: 1,
                                            color: 'white',
                                            backgroundColor: 'red',
                                            padding: '4px',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <strong>Mistakes:</strong> {safeJoin(entry.mistakes)}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            mb: 1,
                                            color: 'white',
                                            backgroundColor: 'green',
                                            padding: '4px',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <strong>Learnings:</strong> {safeJoin(entry.learnings)}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            mb: 1,
                                            color: 'white',
                                            backgroundColor: 'green',
                                            padding: '4px',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <strong>Improvements:</strong> {safeJoin(entry.improvements)}
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        <strong>Profit/Loss:</strong> {entry.profit_loss} - {entry.amount}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Description:</strong> {entry.trade_description || 'N/A'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Container>
    );
};

export default ViewJournal;
