// import React, { useState } from 'react';
// import { Container, Grid, TextField, Button, Typography, Card, CardContent } from '@mui/material';
// import './App.css';

// function NumerologyPage() {
//   const [dob, setDob] = useState('');
//   const [matrix, setMatrix] = useState(Array(9).fill(0));
//   const [driver, setDriver] = useState(null);
//   const [conductor, setConductor] = useState(null);
//   const [error, setError] = useState('');

//   const validateDOB = (dob) => {
//     const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}$/;
//     return regex.test(dob);
//   };

//   const reduceToSingleDigit = (num) => {
//     while (num > 9) {
//       num = num.toString().split('').reduce((acc, digit) => acc + parseInt(digit), 0);
//     }
//     return num;
//   };

//   const calculateNumerology = (dob) => {
//     const digits = dob.replace(/-/g, '').split('').map(Number);

//     const frequency = Array(9).fill(0);
//     digits.forEach(digit => {
//       if (digit > 0) frequency[digit - 1]++;
//     });

//     const day = parseInt(dob.split('-')[0], 10);
//     const driverNum = day.toString().split('').reduce((acc, num) => acc + parseInt(num), 0);
//     const driverFinal = reduceToSingleDigit(driverNum);
//     setDriver(driverFinal);
//     frequency[driverFinal - 1]++; 

//     const conductorNum = digits.reduce((acc, num) => acc + num, 0);
//     const finalConductor = reduceToSingleDigit(conductorNum);
//     setConductor(finalConductor);
//     frequency[finalConductor - 1]++; 

//     setMatrix(frequency);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!validateDOB(dob)) {
//       setError('Invalid Date of Birth. Please enter in DD-MM-YYYY format.');
//       return;
//     }
//     setError('');
//     calculateNumerology(dob);
//   };

//   const formatDOB = (value) => {
//     value = value.replace(/\D/g, '');
//     if (value.length > 2) {
//       value = value.slice(0, 2) + '-' + value.slice(2);
//     }
//     if (value.length > 5) {
//       value = value.slice(0, 5) + '-' + value.slice(5);
//     }
//     return value;
//   };

//   const handleDobChange = (e) => {
//     const formattedDob = formatDOB(e.target.value);
//     setDob(formattedDob);
//   };

//   const getInsightStyle = (condition) => {
//     if (condition === 'good') {
//       return { color: 'green', backgroundColor: '#e6ffe6', padding: '10px', borderRadius: '5px', fontSize: '18px' };
//     } else if (condition === 'caution') {
//       return { color: 'orange', backgroundColor: '#fff5e6', padding: '10px', borderRadius: '5px', fontSize: '18px' };
//     } else if (condition === 'stay-away') {
//       return { color: 'red', backgroundColor: '#ffe6e6', padding: '10px', borderRadius: '5px', fontSize: '18px' };
//     }
//   };

//   const tradeInvestInsights = {
//     1: ['Leadership', 'Ambitious investor'],
//     2: ['Relies on intuition', 'Takes decision emotionally'],
//     3: ['Long-term vision', 'Creative investments', 'Optimistic trades', 'Good Advisor'],
//     4: ['risk taker','improper risk management','greedy'],
//     5: ['Adaptable in volatile markets', 'Quick decision-making', 'Good at communication'],
//     6: ['Focuses on stable returns', 'Harmonious investment strategies', 'Risk-averse'],
//     7: ['Analyzes deeply', 'Intuitive market decisions', 'Spiritual investor'],
//     8: ['Disciplined and focused', 'Powerful trader', 'Seeks material success'],
//     9: ['Aggressive trading', 'Action-driven investor', 'Focused on results'],
//   };

//   const personalityInsights = {
//     1: ['Leadership', 'Ambition', 'Independence'],
//     2: ['Emotional', 'Diplomatic', 'Adaptable'],
//     3: ['Optimism', 'Intellect', 'Generosity'],
//     4: ['Innovative', 'Pragmatic', 'Resilient'],
//     5: ['Communication', 'Versatility', 'Curiosity'],
//     6: ['Harmony', 'Creativity', 'Love and Care'],
//     7: ['Spirituality', 'Intuition', 'Intellectual Depth'],
//     8: ['Discipline', 'Persistence', 'Responsibility'],
//     9: ['Courage', 'Action-Oriented', 'Passion'],
//   };

//   return (
//     <Container maxWidth="lg">
//       <Card style={{ backgroundColor: '#ffffff', padding: '30px' }}>
//         <Typography variant="h3" align="center" gutterBottom>
//           Numerology Stock Market Calculator
//         </Typography>
//         <CardContent>
//           <form onSubmit={handleSubmit}>
//             <Grid container spacing={2} alignItems="center">
//               <Grid item xs={12} sm={4}>
//                 <TextField
//                   fullWidth
//                   variant="outlined"
//                   label="Date of Birth (DD-MM-YYYY)"
//                   value={dob}
//                   onChange={handleDobChange}
//                   placeholder="10-12-1992"
//                   inputProps={{ maxLength: 10 }}
//                   size="small"
//                   error={!!error}
//                   helperText={error}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={2}>
//                 <Button
//                   fullWidth
//                   type="submit"
//                   variant="contained"
//                   color="primary"
//                   size="small"
//                   disabled={!validateDOB(dob)}
//                 >
//                   Calculate
//                 </Button>
//               </Grid>
//             </Grid>
//           </form>

//           {driver && conductor && (
//             <Grid container spacing={4} marginTop={4}>
//               <Grid item xs={12} sm={6}>
//                 <Card style={{ backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', height: '100%' }}>
//                   <CardContent style={{ flex: 1 }}>
//                     <Typography variant="h6" align="center" gutterBottom>
//                       Numerology Matrix
//                     </Typography>
//                     <Grid container spacing={2} style={{ justifyContent: 'center' }}>
//                       {matrix.map((value, index) => (
//                         <Grid item xs={4} key={index}>
//                           <Card style={{ backgroundColor: '#f0f0f0' }}>
//                             <CardContent
//                               style={{
//                                 backgroundColor: '#ffffff',
//                                 textAlign: 'center',
//                                 fontSize: '28px',
//                                 height: '80px',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 justifyContent: 'center',
//                                 border: '1px solid #ddd',
//                                 borderRadius: '5px'
//                               }}
//                             >
//                               {value > 0 ? `${index + 1}`.repeat(value) : '-'}
//                             </CardContent>
//                           </Card>
//                         </Grid>
//                       ))}
//                     </Grid>
//                   </CardContent>
//                 </Card>
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <Card style={{ backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', height: '100%' }}>
//                   <CardContent style={{ flex: 1 }}>
//                     <Grid container spacing={2}>
//                       <Grid item xs={12}>
//                         <Card style={{ backgroundColor: '#f9f9f9', marginBottom: '10px' }}>
//                           <CardContent>
//                             <Typography variant="h6" align="center" gutterBottom>
//                               Trader Verdict
//                             </Typography>
//                             {matrix[4] >= 2 || matrix[5] >= 2 ? (
//                               <Typography style={getInsightStyle('good')}>
//                                 Great Trader/Investor
//                               </Typography>
//                             ) : matrix[4] === 1 || matrix[5] === 1 ? (
//                               <Typography style={getInsightStyle('good')}>
//                                 Good Trader/Investor
//                               </Typography>
//                             ) : null}

//                             {matrix[2] >= 2 || matrix[6] >= 2 ? (
//                               <Typography style={getInsightStyle('good')}>
//                                 Great Advisor
//                               </Typography>
//                             ) : matrix[2] === 1 || matrix[6] === 1 ? (
//                               <Typography style={getInsightStyle('caution')}>
//                                 Good Advisor
//                               </Typography>
//                             ) : null}

//                             {matrix[3] === 1 || matrix[7] === 1 ? (
//                               <Typography style={getInsightStyle('caution')}>
//                                 Stay cautious
//                               </Typography>
//                             ) : null}

//                             {matrix[3] >= 2 || matrix[7] >= 2 ? (
//                               <Typography style={getInsightStyle('stay-away')}>
//                                 Stay away from markets *
//                               </Typography>
//                             ) : null}
//                           </CardContent>
//                         </Card>
//                       </Grid>
//                       <Grid item xs={12}>
//                         <Card style={{ backgroundColor: '#f9f9f9' }}>
//                           <CardContent>
//                             <Typography variant="h6" align="center" gutterBottom>
//                               Trade/Invest Insights
//                             </Typography>
//                             {Array.from({ length: 9 }, (_, index) => index + 1).map(num => (
//                               matrix[num - 1] >= 2 && (
//                                 <Typography key={num} style={getInsightStyle('good')}>
//                                   Number {num}: {tradeInvestInsights[num]?.join(', ') || 'No insights available'}
//                                 </Typography>
//                               )
//                             ))}
//                             {driver && (
//                               <Typography style={getInsightStyle(matrix[driver - 1] >= 2 ? 'good' : 'caution')}>
//                                 Driver Number ({reduceToSingleDigit(driver)}): {tradeInvestInsights[reduceToSingleDigit(driver)]?.join(', ') || 'No insights available'}
//                               </Typography>
//                             )}
//                             {conductor && (
//                               <Typography style={getInsightStyle(matrix[conductor - 1] >= 2 ? 'good' : 'caution')}>
//                                 Conductor Number ({reduceToSingleDigit(conductor)}): {tradeInvestInsights[reduceToSingleDigit(conductor)]?.join(', ') || 'No insights available'}
//                               </Typography>
//                             )}
//                           </CardContent>
//                         </Card>
//                       </Grid>
//                       <Grid item xs={12}>
//                         <Card style={{ backgroundColor: '#f9f9f9' }}>
//                           <CardContent>
//                             <Typography variant="h6" align="center" gutterBottom>
//                               Personality Insights
//                             </Typography>
//                             {Array.from({ length: 9 }, (_, index) => index + 1).map(num => (
//                               matrix[num - 1] >= 2 && (
//                                 <Typography key={num} style={getInsightStyle('good')}>
//                                   Number {num}: {personalityInsights[num]?.join(', ') || 'No insights available'}
//                                 </Typography>
//                               )
//                             ))}
//                             {driver && (
//                               <Typography style={getInsightStyle(matrix[driver - 1] >= 2 ? 'good' : 'caution')}>
//                                 Driver Number ({reduceToSingleDigit(driver)}): {personalityInsights[reduceToSingleDigit(driver)]?.join(', ') || 'No insights available'}
//                               </Typography>
//                             )}
//                             {conductor && (
//                               <Typography style={getInsightStyle(matrix[conductor - 1] >= 2 ? 'good' : 'caution')}>
//                                 Conductor Number ({reduceToSingleDigit(conductor)}): {personalityInsights[reduceToSingleDigit(conductor)]?.join(', ') || 'No insights available'}
//                               </Typography>
//                             )}
//                           </CardContent>
//                         </Card>
//                       </Grid>
//                     </Grid>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             </Grid>
//           )}
//         </CardContent>
//       </Card>
//     </Container>
//   );
// }

// export default NumerologyPage;


import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Typography, Card, CardContent } from '@mui/material';
import './App.css';

function NumerologyPage() {
    const [dob, setDob] = useState('');
    const [matrix, setMatrix] = useState(Array(9).fill(0));
    const [driver, setDriver] = useState(null);
    const [conductor, setConductor] = useState(null);
    const [error, setError] = useState('');

    const validateDOB = (dob) => {
        const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}$/;
        return regex.test(dob);
    };

    const reduceToSingleDigit = (num) => {
        while (num > 9) {
            num = num.toString().split('').reduce((acc, digit) => acc + parseInt(digit), 0);
        }
        return num;
    };

    const calculateNumerology = (dob) => {
        const digits = dob.replace(/-/g, '').split('').map(Number);

        const frequency = Array(9).fill(0);
        digits.forEach(digit => {
            if (digit > 0) frequency[digit - 1]++;
        });

        const day = parseInt(dob.split('-')[0], 10);
        const driverNum = day.toString().split('').reduce((acc, num) => acc + parseInt(num), 0);
        const driverFinal = reduceToSingleDigit(driverNum);
        setDriver(driverFinal);
        frequency[driverFinal - 1]++;

        const conductorNum = digits.reduce((acc, num) => acc + num, 0);
        const finalConductor = reduceToSingleDigit(conductorNum);
        setConductor(finalConductor);
        frequency[finalConductor - 1]++;

        setMatrix(frequency);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateDOB(dob)) {
            setError('Invalid Date of Birth. Please enter in DD-MM-YYYY format.');
            return;
        }
        setError('');
        calculateNumerology(dob);
    };

    const formatDOB = (value) => {
        value = value.replace(/\D/g, '');
        if (value.length > 2) {
            value = value.slice(0, 2) + '-' + value.slice(2);
        }
        if (value.length > 5) {
            value = value.slice(0, 5) + '-' + value.slice(5);
        }
        return value;
    };

    const handleDobChange = (e) => {
        const formattedDob = formatDOB(e.target.value);
        setDob(formattedDob);
    };

    const getInsightStyle = (condition) => {
        if (condition === 'good') {
            return { color: 'green', backgroundColor: '#e6ffe6', padding: '10px', borderRadius: '5px', fontSize: '18px' };
        } else if (condition === 'caution') {
            return { color: 'orange', backgroundColor: '#fff5e6', padding: '10px', borderRadius: '5px', fontSize: '18px' };
        } else if (condition === 'stay-away') {
            return { color: 'red', backgroundColor: '#ffe6e6', padding: '10px', borderRadius: '5px', fontSize: '18px' };
        }
    };

    const tradeInvestInsights = {
        1: ['Leadership', 'Ambitious investor'],
        2: ['Relies on intuition', 'Takes decision emotionally'],
        3: ['Long-term vision', 'Creative investments', 'Optimistic trades', 'Good Advisor'],
        4: ['risk taker', 'improper risk management', 'greedy'],
        5: ['Adaptable in volatile markets', 'Quick decision-making', 'Good at communication'],
        6: ['Focuses on stable returns', 'Harmonious investment strategies', 'Risk-averse'],
        7: ['Analyzes deeply', 'Intuitive market decisions', 'Spiritual investor'],
        8: ['Disciplined and focused', 'Powerful trader', 'Seeks material success'],
        9: ['Aggressive trading', 'Action-driven investor', 'Focused on results'],
    };

    const personalityInsights = {
        1: ['Leadership', 'Ambition', 'Independence','Dominant'],
        2: ['Emotional', 'Diplomatic', 'Mood Swings'],
        3: ['Optimism', 'Intellect', 'Generosity','Happy'],
        4: ['Innovative', 'Pragmatic', 'Resilient'],
        5: ['Communication', 'Versatility', 'Curiosity','Good Speaker'],
        6: ['Harmony', 'Creativity', 'Love and Care'],
        7: ['Spirituality', 'Intuition', 'Intellectual Depth'],
        8: ['Discipline','Dispassion,','Persistence','Introvert','Stubborn'],
        9: ['Anger','Courage', 'Action-Oriented', 'Passion'],
    };

    const analyzeTrade = () => {
        const analysis = [];

        if (matrix[4] === 1) {
            analysis.push(<Typography style={getInsightStyle('good')}>Good Trader</Typography>);
        } else if (matrix[4] === 2) {
            analysis.push(<Typography style={getInsightStyle('good')}>Great Trader</Typography>);
        } else if (matrix[4] >= 3) {
            analysis.push(<Typography style={getInsightStyle('good')}>Excellent Trader</Typography>);
        }

        if (matrix[4] == 0 && matrix[5] > 0) {
            analysis.push(<Typography style={getInsightStyle('caution')}>Average Trader</Typography>);
        }

        if (matrix[2] === 1) {
            analysis.push(<Typography style={getInsightStyle('good')}>Good Advisor</Typography>);
        } else if (matrix[2] > 1) {
            analysis.push(<Typography style={getInsightStyle('good')}>Great Advisor</Typography>);
        }

        if(matrix[2] === 0 ){
            if (matrix[6] === 1) {
                analysis.push(<Typography style={getInsightStyle('caution')}>Average Advisor</Typography>);
            } else if (matrix[6] > 1) {
                analysis.push(<Typography style={getInsightStyle('good')}>Good Advisor</Typography>);
            }
        }
        


        if (matrix[3] === 1 || matrix[7] === 1) {
            analysis.push(<Typography style={getInsightStyle('caution')}>Stay Cautious</Typography>);
        } else if (matrix[4] === 0 && (matrix[3] > 1 || matrix[7] > 1)) {
            analysis.push(<Typography style={getInsightStyle('stay-away')}>Stay away from markets *</Typography>);
        } else if((matrix[4] > 0 && (matrix[3] > 1 || matrix[7] > 1))){
            analysis.push(<Typography style={getInsightStyle('caution')}>Follow Discipline and Avoid Gambling</Typography>);
        }

        return analysis;
    };


    return (
        <Container maxWidth="lg">
            <Card style={{ backgroundColor: '#ffffff', padding: '30px' }}>
                <Typography variant="h3" align="center" gutterBottom>
                    Numerology Stock Market Calculator
                </Typography>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Date of Birth (DD-MM-YYYY)"
                                    value={dob}
                                    onChange={handleDobChange}
                                    placeholder="10-12-1992"
                                    inputProps={{ maxLength: 10 }}
                                    size="small"
                                    error={!!error}
                                    helperText={error}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={!validateDOB(dob)}
                                >
                                    Calculate
                                </Button>
                            </Grid>
                        </Grid>
                    </form>

                    {driver && conductor && (
                        <Grid container spacing={4} marginTop={4}>
                            <Grid item xs={12} sm={6}>
                                <Card style={{ backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <CardContent style={{ flex: 1 }}>
                                        <Typography variant="h6" align="center" gutterBottom>
                                            Numerology Matrix
                                        </Typography>
                                        <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                                            {matrix.map((value, index) => (
                                                <Grid item xs={4} key={index}>
                                                    <Card style={{ backgroundColor: '#f0f0f0' }}>
                                                        <CardContent
                                                            style={{
                                                                backgroundColor: '#ffffff',
                                                                textAlign: 'center',
                                                                fontSize: '28px',
                                                                height: '80px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                border: '1px solid #ddd',
                                                                borderRadius: '5px'
                                                            }}
                                                        >
                                                            {value > 0 ? `${index + 1}`.repeat(value) : '-'}
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Card style={{ backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <CardContent style={{ flex: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Card style={{ backgroundColor: '#f9f9f9', marginBottom: '10px' }}>
                                                    <CardContent>
                                                        <Typography variant="h6" align="center" gutterBottom>
                                                            Trader Verdict
                                                        </Typography>
                                                        {analyzeTrade()}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Card style={{ backgroundColor: '#f9f9f9' }}>
                                                    <CardContent>
                                                        <Typography variant="h6" align="center" gutterBottom>
                                                            Trade/Invest Insights
                                                        </Typography>
                                                        {driver !== conductor && (
                                                            <>
                                                                <Typography style={getInsightStyle(matrix[driver - 1] >= 2 ? 'good' : 'caution')}>
                                                                    Driver Number ({reduceToSingleDigit(driver)}): {tradeInvestInsights[reduceToSingleDigit(driver)]?.join(', ') || 'No insights available'}
                                                                </Typography>
                                                                <Typography style={getInsightStyle(matrix[conductor - 1] >= 2 ? 'good' : 'caution')}>
                                                                    Conductor Number ({reduceToSingleDigit(conductor)}): {tradeInvestInsights[reduceToSingleDigit(conductor)]?.join(', ') || 'No insights available'}
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Card style={{ backgroundColor: '#f9f9f9' }}>
                                                    <CardContent>
                                                        <Typography variant="h6" align="center" gutterBottom>
                                                            Personality Insights
                                                        </Typography>

                                                        {/* Display insights for numbers with frequency of 3 (excluding driver and conductor) */}
                                                        {Array.from({ length: 9 }, (_, index) => index + 1).map(num => (
                                                            matrix[num - 1] === 3 && num !== driver && num !== conductor && (
                                                                <Typography key={num} style={getInsightStyle('good')}>
                                                                    Number {num}: {personalityInsights[num]?.join(', ') || 'No insights available'}
                                                                </Typography>
                                                            )
                                                        ))}

                                                        {/* Display insights for Driver Number */}
                                                        {driver && (
                                                            <Typography style={getInsightStyle(matrix[driver - 1] >= 2 ? 'good' : 'caution')}>
                                                                Driver Number ({reduceToSingleDigit(driver)}): {personalityInsights[reduceToSingleDigit(driver)]?.join(', ') || 'No insights available'}
                                                            </Typography>
                                                        )}

                                                        {/* Display insights for Conductor Number */}
                                                        {conductor && (
                                                            <Typography style={getInsightStyle(matrix[conductor - 1] >= 2 ? 'good' : 'caution')}>
                                                                Conductor Number ({reduceToSingleDigit(conductor)}): {personalityInsights[reduceToSingleDigit(conductor)]?.join(', ') || 'No insights available'}
                                                            </Typography>
                                                        )}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                </CardContent>
            </Card>
        </Container>
    );
}

export default NumerologyPage;
