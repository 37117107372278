import React, { useEffect, useState } from 'react';
import axios from './AxiosInterceptor';
import {
    Container,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Tooltip,
    IconButton,
    Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import config from './config';

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
}));

const TableHeaderStyled = styled(TableHead)(({ theme }) => ({
    backgroundColor: '#1976d2',
    '& th': {
        fontWeight: 'bold',
        color: theme.palette.common.white,
    },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    fontSize: { xs: '0.8rem', sm: '1rem' }, // Responsive font size
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));

const BlackIconButton = styled(IconButton)(({ theme }) => ({
    color: 'black',
}));

const UpcomingResultsPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(config.endpoints.upcomingResults(30)) // Use the endpoint from config.js
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    const handleRowClick = (symbol) => {
        navigate(`/stocks/details/${symbol}`);
    };

    const handleViewClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Box sx={{ mb: 4, padding: 2, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 2 }}>
                <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                    Upcoming Results
                </Typography>
                <TableContainerStyled component={Paper}>
                    <Box sx={{ overflowX: 'auto' }}>
                        <Table>
                            <TableHeaderStyled>
                                <TableRow>
                                    <TableCellStyled>Symbol</TableCellStyled>
                                    {/* <TableCellStyled>Name</TableCellStyled> */}
                                    <TableCellStyled>Result Date</TableCellStyled>
                                    <TableCellStyled>Prev Performance</TableCellStyled>
                                    <TableCellStyled>More Details</TableCellStyled>
                                </TableRow>
                            </TableHeaderStyled>
                            <TableBody>
                                {data.map((item, index) => (
                                    <TableRowStyled key={index}>
                                        <TableCellStyled>{item.symbol}</TableCellStyled>
                                        {/* <TableCellStyled>{item.long_name}</TableCellStyled> */}
                                        <TableCellStyled>{new Date(item.meeting_date).toLocaleDateString()}</TableCellStyled>
                                        <TableCellStyled>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => handleRowClick(item.symbol)}
                                            >
                                                View
                                            </Button>
                                        </TableCellStyled>
                                        <TableCellStyled>
                                            <Tooltip title="View details">
                                                <BlackIconButton onClick={() => handleViewClick(item.url)} size="small">
                                                    <VisibilityIcon fontSize="small" />
                                                </BlackIconButton>
                                            </Tooltip>
                                        </TableCellStyled>
                                    </TableRowStyled>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </TableContainerStyled>
            </Box>
        </Container>
    );
};

export default UpcomingResultsPage;

