import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    IconButton,
    styled,
    Modal,
    Fade,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
} from 'chart.js';
import CloseIcon from '@mui/icons-material/Close';

// Registering components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
}));

const TableHeaderStyled = styled(TableHead)(({ theme }) => ({
    backgroundColor: '#1976d2',
    '& th': {
        fontWeight: 'bold',
        color: theme.palette.common.white,
    },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));

const ModalContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const QuarterlyMovers = () => {
    const [bullish, setBullish] = useState([]);
    const [bearish, setBearish] = useState([]);
    const [loading, setLoading] = useState(true);
    const [quarter, setQuarter] = useState(1);
    const [years, setYears] = useState(1);
    const [limit, setLimit] = useState(10);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState('');

    const fetchQuarterlyData = () => {
        setLoading(true);
        axios
            .get(`/api/top-quarterly-performers?quarter=${quarter}&years=${years}&limit=${limit}`)
            .then((response) => {
                setBullish(response.data.bullish || []);
                setBearish(response.data.bearish || []);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchQuarterlyData();
    }, [quarter, years, limit]);

    const handleQuarterChange = (event) => {
        setQuarter(event.target.value);
    };

    const handleYearsChange = (event) => {
        setYears(event.target.value);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const handleDetailsClick = (symbol) => {
        setSelectedSymbol(symbol);
        setModalOpen(true);
        axios
            .get(`/api/stock-quarter-wise?symbol=${symbol}&quarter=${quarter}`)
            .then((response) => {
                setModalData(response.data || []);
            })
            .catch((error) => {
                console.error('Error fetching modal data:', error);
            });
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const prepareChartData = (data) => {
        const labels = data.map(item => `Q${item.quarter}-${item.year}`);
        const values = data.map(item => parseFloat(item.percentage_change));

        return {
            labels,
            datasets: [
                {
                    label: 'Percentage Change',
                    data: values,
                    backgroundColor: values.map(value => value >= 0 ? 'rgba(76, 175, 80, 0.5)' : 'rgba(244, 67, 54, 0.5)'),
                    borderColor: values.map(value => value >= 0 ? 'rgba(76, 175, 80, 1)' : 'rgba(244, 67, 54, 1)'),
                    borderWidth: 1,
                },
            ],
        };
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        return `${label}: ${value.toFixed(2)}%`;
                    },
                },
                backgroundColor: '#ffffff',
                titleColor: '#000000',
                bodyColor: '#000000',
                borderColor: '#000000',
                borderWidth: 1,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    const renderTable = (data, title) => (
        <Box sx={{ flex: 1, mb: { xs: 2, sm: 0 } }}>
            <TableContainerStyled component={Paper}>
                <Typography variant="h6" sx={{ p: 2 }}>
                    {title}
                </Typography>
                <Table>
                    <TableHeaderStyled>
                        <TableRow>
                            <TableCellStyled>Symbol</TableCellStyled>
                            <TableCellStyled>Average Return (%)</TableCellStyled>
                            <TableCellStyled>Details</TableCellStyled>
                        </TableRow>
                    </TableHeaderStyled>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRowStyled key={index}>
                                <TableCellStyled>{row.symbol}</TableCellStyled>
                                <TableCellStyled sx={{ color: row.average_return >= 0 ? 'green' : 'red' }}>
                                    {parseFloat(row.average_return).toFixed(2)}
                                </TableCellStyled>
                                <TableCellStyled>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() => handleDetailsClick(row.symbol)}
                                    >
                                        <VisibilityIcon sx={{ fontSize: 20, color: 'black' }} />
                                    </IconButton>
                                </TableCellStyled>
                            </TableRowStyled>
                        ))}
                    </TableBody>
                </Table>
            </TableContainerStyled>
        </Box>
    );

    return (
        <Container maxWidth="lg" sx={{ mt: 4, px: { xs: 2, sm: 4 } }}>
            <Box sx={{ mb: 4, padding: 1, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 2 }}>
                <Container maxWidth="lg" sx={{ mt: 4 }}>
                    <Typography variant="h4">Quarterly Movers</Typography>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 2, padding: 2 }}>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="quarter-select-label">Quarter</InputLabel>
                            <Select
                                labelId="quarter-select-label"
                                id="quarter-select"
                                value={quarter}
                                onChange={handleQuarterChange}
                                label="Quarter"
                            >
                                {[1, 2, 3, 4].map((q) => (
                                    <MenuItem key={q} value={q}>
                                        Q{q}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="years-select-label">Years</InputLabel>
                            <Select
                                labelId="years-select-label"
                                id="years-select"
                                value={years}
                                onChange={handleYearsChange}
                                label="Years"
                            >
                                {[1, 2, 3, 4, 5].map((y) => (
                                    <MenuItem key={y} value={y}>
                                        {y}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="limit-select-label">Limit</InputLabel>
                            <Select
                                labelId="limit-select-label"
                                id="limit-select"
                                value={limit}
                                onChange={handleLimitChange}
                                label="Limit"
                            >
                                {[5, 10, 15].map((l) => (
                                    <MenuItem key={l} value={l}>
                                        {l}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
                        {renderTable(bullish, 'Bullish Movers')}
                        {renderTable(bearish, 'Bearish Movers')}
                    </Box>
                </Container>

                {/* Modal for details */}
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    closeAfterTransition
                >
                    <Fade in={modalOpen}>
                        <ModalContent sx={{
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            boxShadow: 24,
                            width: { xs: '90%', sm: '80%', md: '60%' },
                            maxHeight: '80vh',
                            overflowY: 'auto',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Typography variant="h6">{selectedSymbol} - Quarterly Data</Typography>
                                <IconButton onClick={handleCloseModal}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Bar data={prepareChartData(modalData)} options={chartOptions} />
                        </ModalContent>
                    </Fade>
                </Modal>
            </Box>
        </Container>
    );
};

export default QuarterlyMovers;
