const config = {
	apiBaseUrl: 'https://stockbaaz.com/api',
    endpoints: {
        financialResults: (limit, days) => `${config.apiBaseUrl}/financial-results?limit=${limit}&days=${days}`,
        priceChanges: (symbol, quarters) => `${config.apiBaseUrl}/price-changes/${symbol}?quarters=${quarters}`,
        upcomingResults: (days) => `${config.apiBaseUrl}/upcoming-results?days=${days}`,
        budgetStocks: (type, limit) => `${config.apiBaseUrl}/budget-stocks/?type=${type}&limit=${limit}`, // Assuming similar endpoint
        lunarAnalysis: (index, type, limit) => `${config.apiBaseUrl}/astro-events?index=${index}&type=${type}&limit=${limit}`,
        gannDates: (index, dayMonth, limit) => 
      `${config.apiBaseUrl}/gann-dates?index=${index}&dayMonth=${dayMonth}&limit=${limit}`,
        tradingJournal: () => `${config.apiBaseUrl}/journal`,
    }
};

export default config;
