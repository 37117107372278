import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  IconButton,
  styled,
  CircularProgress,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
}));

const TableHeaderStyled = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#1976d2',
  '& th': {
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

// Table component to display stocks
const StocksTable = ({ data, loading }) => {
  if (loading) {
    return <CircularProgress />;
  }

  if (!data || data.length === 0) {
    return <Typography>No data available</Typography>;
  }

  return (
    <TableContainerStyled component={Paper}>
      <Table>
        <TableHeaderStyled>
          <TableRow>
            <TableCellStyled>Stock Symbol</TableCellStyled>
            <TableCellStyled>Triggered At</TableCellStyled>
            <TableCellStyled>Price</TableCellStyled>
            <TableCellStyled>Details</TableCellStyled>
          </TableRow>
        </TableHeaderStyled>
      </Table>
      <div style={{ maxHeight: '230px', overflowY: 'auto' }}>
        <Table>
          <TableBody>
            {data.map((stock, index) => (
              <TableRowStyled key={index}>
                <TableCellStyled>{stock.stock_symbol}</TableCellStyled>
                <TableCellStyled>{stock.triggered_at}</TableCellStyled>
                <TableCellStyled>{stock.trigger_price}</TableCellStyled>
                <TableCellStyled>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => window.open(`https://chartink.com/stocks-new?symbol=${stock.stock_symbol}`, '_blank')}
                  >
                    <VisibilityIcon sx={{ fontSize: 20, color: 'black' }} />
                  </IconButton>
                </TableCellStyled>
              </TableRowStyled>
            ))}
          </TableBody>
        </Table>
      </div>
    </TableContainerStyled>
  );
};

const AlertPage = () => {
  const [highData, setHighData] = useState([]);
  const [lowData, setLowData] = useState([]);
  const [loadingHigh, setLoadingHigh] = useState(true);
  const [loadingLow, setLoadingLow] = useState(true);

  useEffect(() => {
    const fetchHighData = async () => {
      try {
        const response = await axios.get('/api/latest-stocks', {
          params: { scan_name: "Close Near Day's High" },
        });
        setHighData(response.data);
      } catch (error) {
        console.error('Error fetching high data:', error);
      } finally {
        setLoadingHigh(false);
      }
    };

    const fetchLowData = async () => {
      try {
        const response = await axios.get('/api/latest-stocks', {
          params: { scan_name: "Day low closing" },
        });
        setLowData(response.data);
      } catch (error) {
        console.error('Error fetching low data:', error);
      } finally {
        setLoadingLow(false);
      }
    };

    fetchHighData();
    fetchLowData();
  }, []);

  return (
    <Box sx={{ mt: 4, px: { xs: 2, sm: 4 } }}>
      <Typography variant="h4" gutterBottom>
        Alerts
      </Typography>

      {/* Container for both sections */}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'flex-start',
        }}
      >
        {/* First column: Near Day's High */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" gutterBottom>
            Near Day's High
          </Typography>
          <StocksTable data={highData} loading={loadingHigh} />
        </Box>

        {/* Second column: Near Day's Low */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" gutterBottom>
            Near Day's Low
          </Typography>
          <StocksTable data={lowData} loading={loadingLow} />
        </Box>
      </Box>
    </Box>
  );
};

export default AlertPage;

