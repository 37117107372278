import React, { useEffect, useState } from 'react';
import axios from './AxiosInterceptor';
import { Container, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Tooltip, IconButton, Select, MenuItem, InputLabel, FormControl, Tabs, Tab, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import config from './config'; // Import the config file
import UpcomingResultsPage from './UpcomingResultsPage'; // Import UpcomingResultsPage

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
}));

const TableHeaderStyled = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#1976d2',
  '& th': {
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  '@media (max-width: 600px)': {
    padding: theme.spacing(0.5), // Reduce padding for smaller screens
  },
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const BlackIconButton = styled(IconButton)(({ theme }) => ({
  color: 'black',
}));

const ResultMoverPage = () => {
  const [data, setData] = useState({ bullish: [], bearish: [] });
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [tab, setTab] = useState('bullish');
  const [range, setRange] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios.get(config.endpoints.financialResults(limit, range))
      .then(response => {
        const { bestPerformers, worstPerformers } = response.data;
        const fetchedData = {
          bullish: bestPerformers || [],
          bearish: worstPerformers || []
        };
        setData(fetchedData);
        setFilteredData(fetchedData.bullish);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [limit, range]);

  const handleRowClick = (symbol) => {
    navigate(`/stocks/details/${symbol}`);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    if (newValue !== 'upcoming') {
      setFilteredData(data[newValue]);
    }
  };

  const handleSearch = (filteredResults) => {
    setFilteredData(filteredResults);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, px: { xs: 2, sm: 4 }, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, px: { xs: 2, sm: 4 } }}>
      <Box sx={{ mb: 4, padding: 2, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
          Stock Performance After Financial Results
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, padding: 2 }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="limit-select-label">Limit</InputLabel>
            <Select
              labelId="limit-select-label"
              id="limit-select"
              value={limit}
              onChange={handleLimitChange}
              label="Limit"
            >
              <MenuItem value={5}>Top 5</MenuItem>
              <MenuItem value={10}>Top 10</MenuItem>
              <MenuItem value={20}>Top 20</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="range-select-label">Range</InputLabel>
            <Tooltip title="Range is the number of working days after result date for which impact is calculated" placement="top">
              <Select
                labelId="range-select-label"
                id="range-select"
                value={range}
                onChange={handleRangeChange}
                label="Date Range"
              >
                <MenuItem value={1}>1 Day</MenuItem>
                <MenuItem value={7}>1 Week</MenuItem>
                <MenuItem value={30}>1 Month</MenuItem>
              </Select>
            </Tooltip>
          </FormControl>
        </Box>
        <Tabs value={tab} onChange={handleTabChange} aria-label="stock performance tabs">
          <Tab label="Bullish" value="bullish" />
          <Tab label="Bearish" value="bearish" />
          <Tab label="Upcoming Results" value="upcoming" /> {/* Add the new tab */}
        </Tabs>

        {/* Conditionally render the content based on the active tab */}
        {tab === 'upcoming' ? (
          <UpcomingResultsPage />  // Render UpcomingResultsPage when this tab is active
        ) : (
          <div style={{ overflowX: 'auto' }}> {/* Allow horizontal scrolling */}
            <TableContainerStyled component={Paper}>
              <Table>
                <TableHeaderStyled>
                  <TableRow>
                    <TableCellStyled>Name</TableCellStyled>
                    <TableCellStyled>Financial Result Date</TableCellStyled>
                    <TableCellStyled>Next Date</TableCellStyled>
                    <TableCellStyled>Prev Close</TableCellStyled>
                    <TableCellStyled>Next Close</TableCellStyled>
                    <TableCellStyled>% Change</TableCellStyled>
                    <TableCellStyled>Details</TableCellStyled>
                  </TableRow>
                </TableHeaderStyled>
                <TableBody>
                  {filteredData.map((row, index) => (
                    <TableRowStyled key={index} onClick={() => handleRowClick(row.symbol)}>
                      <TableCellStyled>{row.symbol}</TableCellStyled>
                      <TableCellStyled>{row.financial_result_date.slice(0, 10)}</TableCellStyled>
                      <TableCellStyled>{row.stock_date.slice(0, 10)}</TableCellStyled>
                      <TableCellStyled>{Number(row.previous_cls_price).toFixed(2)}</TableCellStyled>
                      <TableCellStyled>{Number(row.closing_price).toFixed(2)}</TableCellStyled>
                      <TableCellStyled sx={{ color: row.percent_change_close >= 0 ? 'green' : 'red' }}>
                        {Number(row.percent_change_close).toFixed(2)}%
                      </TableCellStyled>
                      <TableCellStyled>
                        <Tooltip title="View details">
                          <BlackIconButton size="small">
                            <VisibilityIcon fontSize="small" />
                          </BlackIconButton>
                        </Tooltip>
                      </TableCellStyled>
                    </TableRowStyled>
                  ))}
                </TableBody>
              </Table>
            </TableContainerStyled>
          </div>
        )}
      </Box>
    </Container>
  );
};

export default ResultMoverPage;
