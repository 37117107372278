import axios from 'axios';
import { getAuth } from 'firebase/auth';
// import { useNavigate } from 'react-router-dom';

// Request Interceptor
// axios.interceptors.request.use(
//   (config) => {
//     // Retrieve the token from cookies
//     const token = document.cookie
//       .split('; ')
//       .find(row => row.startsWith('authToken='))
//       ?.split('=')[1];

//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`;
//     }

//     console.log('Request:', config);
//     return config; // Continue with the request
//   },
//   (error) => {
//     console.error('Request error:', error);
//     return Promise.reject(error); // Reject the request
//   }
// );

// Optional: You can add response interceptors here as well
// axios.interceptors.response.use(
//   (response) => {
//     // Process the response
//     console.log('Response:', response);
//     return response;
//   },
//   (error) => {
//     console.error('Response error:', error);
//     return Promise.reject(error);
//   }
// );


const getTokenFromCookies = (name) => {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith(`${name}=`))
    ?.split('=')[1];
};

const saveTokenToCookies = (name, token) => {
  document.cookie = `${name}=${token}; path=/; secure`;
};

const clearCookies = () => {
  document.cookie = 'authToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure';
};

// const navigate = useNavigate();

//   const logout = () => {
//     clearCookies();
//     // useNavigate('/signin'); // Redirect to login page on logout
//   };

const logout = async () => {
    const auth = getAuth();
  
    try {
      // Sign out the user
      await auth.signOut();
      
      // Optionally clear any tokens you may have saved
      clearCookies();      
      // Redirect to login page or show logged out message
      window.location.href = '/signin'; // Or use React router's navigate if it's a SPA
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };


const refreshAccessToken = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      logout(); // If no user is logged in, log out
      return null;
    }
  
    try {
      // Force a refresh of the Firebase access token
      const newAccessToken = await user.getIdToken(true); // true forces a token refresh
  
      // Optionally save the new token to cookies/localStorage
      saveTokenToCookies('authToken', newAccessToken);
  
      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing Firebase token:', error);
      logout(); // Log out if token refresh fails
      return null;
    }
  };

  axios.interceptors.request.use(
    async (config) => {
      let token = getTokenFromCookies('authToken');

      if (token) {
        const tokenParts = JSON.parse(atob(token.split('.')[1]));
        const now = Math.floor(Date.now() / 1000);

        // If token will expire in the next 60 seconds, refresh it
        if (tokenParts.exp - now < 60) {
          token = await refreshAccessToken();

          if (!token) {
            return Promise.reject('Unable to refresh token');
          }
        }

        // Set the Authorization header
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error); // Reject request if error
    }
  );

  // Response Interceptor
  axios.interceptors.response.use(
    (response) => {
      return response; // Return the response if successful
    },
    async (error) => {
      const originalRequest = error.config;

      // If response is 401 Unauthorized and request hasn't been retried
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Mark the request as retried
        const token = await refreshAccessToken(); // Try to refresh the access token

        if (token) {
          // Update the Authorization header and retry the request
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return axios(originalRequest);
        }
      }
    //   logout(); // Log out if refresh fails or another error occurs
    //   return Promise.reject(error);
    }
  );

export default axios; // Export the global axios instance