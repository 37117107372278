import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import { Card, CardContent, Typography, Grid, Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import './LiveMarketCards.cs s'; // Import your custom CSS for animations

/*
const socket = io('http://ec2-13-60-156-60.eu-north-1.compute.amazonaws.com:4000', {
    transports: ['websocket'],
});
*/

const socket = io('https://stockbaaz.com', {
    transports: ['websocket'],
});

// Listening for connection
socket.on('connect', () => {
    console.log('WebSocket connected');
});

// Listening for messages
socket.on('message', (data) => {
    console.log('Message received:', data);
});

// Handling errors
socket.on('error', (error) => {
    console.error('WebSocket error:', error);
});

// Disconnecting
socket.on('disconnect', () => {
    console.log('WebSocket disconnected');
});


const LiveMarketCards = () => {
    const [niftyData, setNiftyData] = useState(null);
    const [bankNiftyData, setBankNiftyData] = useState(null);
    const [realTimeFeeds, setRealTimeFeeds] = useState([]);
    const feedQueue = useRef([]);
    const isProcessingQueue = useRef(false);

    useEffect(() => {
        socket.on('tickData', (data) => {
            data.forEach(tick => {
                if (tick.instrument_token === 256265) { // Nifty 50
                    setNiftyData(tick);
                } else if (tick.instrument_token === 260105) { // Bank Nifty
                    setBankNiftyData(tick);
                }
            });
        });

        socket.on('priceBreak', (message) => {
            feedQueue.current.push({
                message: message,
                time: new Date().toLocaleTimeString(),
            });
            processQueue();
        });

        return () => {
            socket.off('tickData');
            socket.off('priceBreak');
        };
    }, []);

    const processQueue = () => {
        if (!isProcessingQueue.current && feedQueue.current.length > 0) {
            isProcessingQueue.current = true;
            const nextFeed = feedQueue.current.shift();
            setRealTimeFeeds(prevFeeds => [
                ...prevFeeds,
                nextFeed,
            ]);

            setTimeout(() => {
                isProcessingQueue.current = false;
                processQueue();
            }, 2000); // 2-second delay between feeds
        }
    };

    const renderCard = (title, data) => {
        const priceChangeColor = data?.last_price > data?.ohlc?.close ? '#4caf50' : '#f44336'; // Green for up, Red for down
        const gradientBackground = `linear-gradient(45deg, ${priceChangeColor} 30%, #333 90%)`;

        return (
            <Card style={{ margin: '20px', padding: '10px', background: gradientBackground, color: '#fff' }}>
                <CardContent>
                    <Typography variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="h4" component="div" style={{ fontWeight: 'bold', marginTop: '20px' }}>
                        {data ? data.last_price : 'Loading...'}
                    </Typography>
                </CardContent>
            </Card>
        );
    };

    const getColorForMessage = (message) => {
        if (message.startsWith('🔼')) {
            return '#4caf50'; // Green
        } else if (message.startsWith('🔽')) {
            return '#f44336'; // Red
        }
        return 'white'; // Default color if no match
    };

    const renderRealTimeFeeds = () => (
        <Card style={{ margin: '20px', padding: '10px', background: '#424242', color: '#fff', flexGrow: 1 }}>
            <CardContent>
                <Typography variant="h5" component="div" style={{ marginBottom: '10px', position: 'sticky', top: 0, backgroundColor: '#424242', zIndex: 1 }}>
                    Real-Time Feeds
                </Typography>
                <Box style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <TransitionGroup component={List}>
                        {realTimeFeeds.slice(-10).reverse().map((feed, index) => (
                            <CSSTransition key={index} timeout={500} classNames="feed">
                                <ListItem style={{ backgroundColor: index % 2 === 0 ? '#333' : '#444', margin: '5px 0', borderRadius: '4px' }}>
                                    <ListItemText
                                        primary={feed.message}
                                        secondary={`Time: ${feed.time}`}
                                        primaryTypographyProps={{ style: { color: getColorForMessage(feed.message), fontWeight: 'bold' } }}
                                        secondaryTypographyProps={{ style: { color: '#bbb' } }}
                                    />
                                    <Divider style={{ backgroundColor: '#555' }} />
                                </ListItem>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </Box>
            </CardContent>
        </Card>
    );

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6}>
                    {renderCard('Nifty 50', niftyData)}
                </Grid>
                <Grid item xs={12} md={6}>
                    {renderCard('Bank Nifty', bankNiftyData)}
                </Grid>
                <Grid item xs={12} style={{ flexGrow: 1 }}>
                    {renderRealTimeFeeds()}
                </Grid>
            </Grid>
        </div>
    );
}

export default LiveMarketCards;
