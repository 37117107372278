import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Select, MenuItem, FormControl, InputLabel, CircularProgress, IconButton, Modal, styled, Fade } from '@mui/material';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
}));

const TableHeaderStyled = styled(TableHead)(({ theme }) => ({
    backgroundColor: '#1976d2',
    '& th': {
        fontWeight: 'bold',
        color: theme.palette.common.white,
    },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));

const chartOptions = {
    responsive: true,
    plugins: {
        legend: { position: 'top' },
        tooltip: {
            callbacks: {
                label: (context) => `${context.dataset.label}: ${context.raw.toFixed(2)}%`,
            },
        },
    },
    scales: {
        x: { beginAtZero: true },
        y: { beginAtZero: true },
    },
};

const MonthWiseStocksPage = () => {
    const [bullishStocks, setBullishStocks] = useState([]);
    const [bearishStocks, setBearishStocks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [month, setMonth] = useState(1);
    const [n, setN] = useState(1);
    const [limit, setLimit] = useState(10);
    const [modalOpen, setOpenModal] = useState(false);
    const [selectedSymbol, setSelectedSymbol] = useState('');
    const [modalData, setModalData] = useState([]);

    const ModalContent = styled(Box)(({ theme }) => ({
        padding: theme.spacing(2),
        '@media (max-width:600px)': {
            padding: theme.spacing(1),
        },
    }));

    const months = Array.from({ length: 12 }, (_, i) => i + 1);

    const fetchData = () => {
        setLoading(true);
        axios
            .get(`/top-performers?month=${month}&n=${n}&limit=${limit}`) // Added limit parameter
            .then((response) => {
                const topPerformers = response.data.top_performers;

                const sortedStocks = [...topPerformers].sort((a, b) => Number(b.average) - Number(a.average));
                const bullish = sortedStocks.slice(0, limit);
                const bearish = sortedStocks.slice(-limit).reverse();

                setBullishStocks(bullish);
                setBearishStocks(bearish);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [month, n, limit]);

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const handleNChange = (event) => {
        setN(event.target.value);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleDetailsClick = (stock) => {
        setSelectedSymbol(stock.symbol);
        setOpenModal(true);
	const encodedSymbol = encodeURIComponent(stock.symbol);    
        axios.get(`/stock?symbol=${encodedSymbol}&month=${month}`)
            .then(response => {
                setModalData(response.data || {});
            })
            .catch(error => {
                console.error('Error fetching bar data:', error);
            });
    };

    const prepareChartData = (data) => {
        const labels = Object.keys(data);
        const values = labels.map(key => parseFloat(data[key]));

        return {
            labels,
            datasets: [
                {
                    label: 'Percentage Change',
                    data: values,
                    backgroundColor: values.map(value => value >= 0 ? 'rgba(76, 175, 80, 0.5)' : 'rgba(244, 67, 54, 0.5)'),
                    borderColor: values.map(value => value >= 0 ? 'rgba(76, 175, 80, 1)' : 'rgba(244, 67, 54, 1)'),
                    borderWidth: 1,
                },
            ],
        };
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, px: { xs: 2, sm: 4 } }}>
            <Box sx={{ mb: 4, padding: 2, backgroundColor: 'background.paper', borderRadius: 1, boxShadow: 2 }}>
                <Typography variant="h4" gutterBottom>Bullish and Bearish By Month</Typography>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="month-select-label">Month</InputLabel>
                        <Select
                            labelId="month-select-label"
                            id="month-select"
                            value={month}
                            onChange={handleMonthChange}
                            label="Month"
                        >
                            {months.map((m) => (
                                <MenuItem key={m} value={m}>
                                    {new Date(0, m - 1).toLocaleString('default', { month: 'long' })}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="n-select-label">Prev (Years)</InputLabel>
                        <Select
                            labelId="n-select-label"
                            id="n-select"
                            value={n}
                            onChange={handleNChange}
                            label="Prev (Years)"
                        >
                            {[1, 2, 3, 4, 5].map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value} Year{value > 1 ? 's' : ''}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="limit-select-label">Limit</InputLabel>
                        <Select
                            labelId="limit-select-label"
                            id="limit-select"
                            value={limit}
                            onChange={handleLimitChange}
                            label="Limit"
                        >
                            {[5, 10, 15].map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mt: 2 }}>
                    <TableContainerStyled component={Paper} sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ p: 2 }}>
                            Bullish Stocks
                        </Typography>
                        <Table>
                            <TableHeaderStyled>
                                <TableRow>
                                    <TableCellStyled>Symbol</TableCellStyled>
                                    <TableCellStyled>% Avg chng</TableCellStyled>
                                    <TableCellStyled>Details</TableCellStyled>
                                </TableRow>
                            </TableHeaderStyled>
                            <TableBody>
                                {bullishStocks.map((stock, index) => (
                                    <TableRowStyled key={index}>
                                        <TableCellStyled>{stock.symbol}</TableCellStyled>
                                        <TableCellStyled style={{ color: stock.average >= 0 ? 'green' : 'red' }}>
                                            {stock.average}
                                        </TableCellStyled>
                                        <TableCellStyled>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => handleDetailsClick(stock)}
                                            >
                                                <VisibilityIcon sx={{ fontSize: 20, color: 'black' }} />
                                            </IconButton>
                                        </TableCellStyled>
                                    </TableRowStyled>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainerStyled>

                    <TableContainerStyled component={Paper} sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ p: 2 }}>
                            Bearish Stocks
                        </Typography>
                        <Table>
                            <TableHeaderStyled>
                                <TableRow>
                                    <TableCellStyled>Symbol</TableCellStyled>
                                    <TableCellStyled>% Avg chng</TableCellStyled>
                                    <TableCellStyled>Details</TableCellStyled>
                                </TableRow>
                            </TableHeaderStyled>
                            <TableBody>
                                {bearishStocks.map((stock, index) => (
                                    <TableRowStyled key={index}>
                                        <TableCellStyled>{stock.symbol}</TableCellStyled>
                                        <TableCellStyled style={{ color: stock.average >= 0 ? 'green' : 'red' }}>
                                            {stock.average}
                                        </TableCellStyled>
                                        <TableCellStyled>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => handleDetailsClick(stock)}
                                            >
                                                <VisibilityIcon sx={{ fontSize: 20, color: 'black' }} />
                                            </IconButton>
                                        </TableCellStyled>
                                    </TableRowStyled>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainerStyled>
                </Box>
            </Box>

            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                closeAfterTransition
            >
                <Fade in={modalOpen}>
                    <ModalContent
                        sx={{
                            width: '90%',
                            maxWidth: '600px',
                            margin: 'auto',
                            backgroundColor: 'white',
                            padding: 2,
                            position: 'relative',
                        }}
                    >
                        {/* Close icon positioned at the top right corner */}
                        <IconButton
                            onClick={handleCloseModal}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                        >
                            <CloseIcon sx={{ fontSize: 24, color: 'black' }} />
                        </IconButton>

                        <Typography variant="h6" gutterBottom>
                            Stock Data for {selectedSymbol}
                        </Typography>

                        <Box sx={{ mb: 2 }}>
                            <Bar
                                data={prepareChartData(modalData.data || {})}  // Access modalData.data
                                options={chartOptions}
                            />
                        </Box>
                    </ModalContent>
                </Fade>
            </Modal>
        </Container>
    );
};

export default MonthWiseStocksPage;



